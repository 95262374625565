// Attribute
// TODO: Change get to find
export const ADD_ATTRIBUTE = "ADD_ATTRIBUTE";
export const EDIT_ATTRIBUTE = "EDIT_ATTRIBUTE";
export const GET_ATTRIBUTES = "GET_ATTRIBUTES";
export const DELETE_ATTRIBUTE = "DELETE_ATTRIBUTE";
export const FIND_ATTRIBUTE = "FIND_ATTRIBUTE";

// Group
export const ADD_GROUP = "ADD_GROUP";
export const EDIT_GROUP = "EDIT_GROUP";
export const GET_GROUPS = "GET_GROUPS";
export const DELETE_GROUPS = "DELETE_GROUPS";
export const FIND_GROUP = "FIND_GROUP";

// Customer Attributes
export const ADD_CUSTOMER_ATTRIBUTES = "ADD_CUSTOMER_ATTRIBUTES";
export const EDIT_CUSTOMER_ATTRIBUTES = "EDIT_CUSTOMER_ATTRIBUTES";
export const GET_CUSTOMER_ATTRIBUTES = "GET_CUSTOMER_ATTRIBUTES";
export const DELETE_CUSTOMER_ATTRIBUTE = "DELETE_CUSTOMER_ATTRIBUTE";
export const FIND_CUSTOMER_ATTRIBUTE = "FIND_CUSTOMER_ATTRIBUTE";
export const GET_ONE_LIST_ATTRIBUTES = "GET_ONE_LIST_ATTRIBUTES";

// Customer Attributes
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";

// Customer Addresses
export const ADD_ADDRESS = "ADD_ADDRESS";
export const GET_ADDRESSES = "GET_ADDRESSES";  
export const DELETE_ADRESS = "DELETE_ADRESS";
export const FIND_ADDRESS = "FIND_ADDRESS";  
export const EDIT_ADRESS = "EDIT_ADRESS";

// segmentation
export const ADD_SEGMENTATION = "ADD_SEGMENTATION";
export const GET_SEGMENTATIONS = "GET_SEGMENTATIONS";
export const DELETE_SEGMENTATION = "DELETE_SEGMENTATION";
export const EDIT_SEGMENTATION = "EDIT_SEGMENTATION";
export const FIND_SEGMENTATION = "FIND_SEGMENTATION";
export const DUPLICATE_SEGMENTATION = "DUPLICATE_SEGMENTATION";

export const ADD_TEMPLATE_SEGMENTATION = "ADD_TEMPLATE_SEGMENTATION";
export const GET_TEMPLATE_SEGMENTATIONS = "GET_TEMPLATE_SEGMENTATIONS";
export const DELETE_TEMPLATE_SEGMENTATION = "DELETE_TEMPLATE_SEGMENTATION";
export const EDIT_TEMPLATE_SEGMENTATION = "EDIT_TEMPLATE_SEGMENTATION";
export const FIND_TEMPLATE_SEGMENTATION = "FIND_TEMPLATE_SEGMENTATION";

// TAGS
export const ADD_TAG = "ADD_TAG";
export const GET_TAGS = "GET_TAGS";
export const DELETE_TAG = "DELETE_TAG";
export const EDIT_TAG = "EDIT_TAG";
export const FIND_TAG = "FIND_TAG";
export const DUPLICATE_TAG = "DUPLICATE_TAG";

export const GET_TEMPLATE_TAGS = "GET_TEMPLATE_TAGS";
export const FIND_TEMPLATE_TAG = "FIND_TEMPLATE_TAG";

//CRM Settings
