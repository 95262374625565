import {
  ADD_SHIPMENT,
  EDIT_SHIPMENT,
  DELETE_SHIPMENT,
  GET_SHIPMENTS,
  FIND_SHIPMENT,
} from "../../actions/OrderMangement/types";

const initialState = {
  item: null,
  items: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case EDIT_SHIPMENT:
    case FIND_SHIPMENT:
    case DELETE_SHIPMENT:
      return {
        ...state,
        item: action.payload,
      };
    case GET_SHIPMENTS:
      return {
        ...state,
        items: action.payload,
      };
    case ADD_SHIPMENT:
      return {
        ...state,
        item: action.payload,
        items: [...state.items, action.payload],
      };
    default:
      return state;
  }
}
