import React, { useState } from "react";
import ModalComponent from "../../../../components/general/Modal";
import FormComponent from "../../../../components/general/FormController";
import _ from "lodash";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  Alert,
} from "reactstrap";
import Options from "../../../../components/general/Attribute/Options";

const View = (props) => {
  const {
    onChange,
    data,
    onChangeSelect,
    onChangeCheckbox,
    errors,
    toggle,
    modal,
  } = props;
  return (
    <div>
      <Button className="btn btn-primary btn-cons" onClick={toggle}>
        {"Create Attribute"}
      </Button>
      <ModalComponent
        title="Create New Attribute"
        toggle={toggle}
        isOpen={modal}
        size="lg"
      >
        <Form onSubmit={props.addAttribute}>
          <FormComponent
            type="text"
            name="name_en"
            label="English Label"
            value={data.name_en}
            updateState={onChange}
            //required = {true}
            slug={true}
          />
          <FormComponent
            type="text"
            name="name_ar"
            label="Arabic Label"
            value={data.name_ar}
            updateState={onChange}
            //required = {true}
          />
          <FormComponent
            type="text"
            name="inner_name"
            label="Internal Name"
            value={data.inner_name}
            updateState={onChange}
            disabled={false}
            //required = {true}
          />
          <FormComponent
            type="textarea"
            name="description_en"
            label="English Description"
            value={data.description_en}
            updateState={onChange}
          />
          <FormComponent
            type="textarea"
            name="description_ar"
            label="Arabic Description"
            value={data.description_ar}
            updateState={onChange}
          />
          {props.groupList ? (
            <div>
              <FormComponent
                type="select"
                options={props.groupList}
                name="group_id"
                label="Group Atributes"
                value={data.group_id}
                valueKey="_id"
                labelKey="label"
                updateState={onChange}
              />
            </div>
          ) : (
            "Loading"
          )}
          <FormComponent
            type="select"
            options={props.attributeTypes}
            name="type"
            targetLabel="typeLabel"
            label="Type"
            value={data.type}
            valueLabel={data.typeLabel}
            valueKey="value"
            labelKey="label"
            optional="typeCase"
            optionalKey="case"
            updateState={onChange}
          />

          {/*checkbox Group options case 2 */}

          {(data.typeCase === 2 || data.typeCase === 3) && (
            <Options
              data = {data}
              onAddOption={props.onAddOption}
              typeCase={data.typeCase}
              options={props.options}
              hasAddOption={true}
              updateState={onChange}
            />
          )}

          {/* Single checkbox Option case 1 */}
          {data.typeCase === 1 && (
            <Options
              data = {data}
              onAddOption={props.onAddOption}
              typeCase={data.typeCase}
              options={props.options}
              updateState={onChange}
            />
          )}
          <FormComponent
            type="singleCheckbox"
            name="is_required"
            label="Is required?"
            value={data.is_required}
            defaultChecked={data.is_required}
            updateState={onChange}
          />
          <FormComponent
            type="singleCheckbox"
            name="in_form"
            label="In form?"
            value={data.in_form}
            defaultChecked={data.in_form}
            updateState={onChange}
          />
          <ModalFooter>
            {errors &&
              _.map(errors, (error) => <Alert color="danger"> {error} </Alert>)}
            <Button
              //type="submit"
              className="btn btn-default"
              onClick={props.onValidate}
            >
              {"Create"}
            </Button>
            <Button className="btn btn-secondary" onClick={toggle}>
              {"Cancel"}
            </Button>
          </ModalFooter>
        </Form>
      </ModalComponent>
    </div>
  );
};

export default View;
