import React from "react";
import Table from "../../../components/general/Table";
import Card from "../../../components/general/Card";
import HeaderActions from "../../../components/general/HeaderBtns";
import AddBrand from "./Add";

const View = (props) => {
  let { brands, deleteBrand, handleCreate } = props;
  console.log(props);
  const headerList = [
    {
      type: "_id",
      isKey: true,
      hidden: true,
      title: "ID",
    },
    {
      sortable: true,
      type: "name_en",
      align: "center",
      title: "Name",
    },
    {
      sortable: true,
      type: "name_ar",
      align: "center",
      title: "الإسم",
    },
    {
      sortable: true,
      type: "status",
      align: "center",
      title: "Status",
    },
    {
      sortable: true,
      type: "priority",
      align: "center",
      title: "Priority",
    },
    {
      type: "action",
      sortable: true,
      align: "center",
      export: false,
      title: "Action",
    },
  ];
  return (
    <>
      <Card>
        <HeaderActions
          createAction={false}
          importAction={{ title: "Import", onClick: () => {} }}
          ActionList={{ title: "Action", list: "Delete Brandation" }}
        >
         <AddBrand {...props} />
         </HeaderActions>

        <div className="contact-table">
          <Table
            data={brands}
            isSelectRow={true}
            EDIT_URL={"ecommerce/edit_Brand"}
            deleteAction={deleteBrand}
            headers={headerList}
            {...props}
          />
        </div>
        <div className="verified-container m-t-10">
          <ul className="list-unstyled">
            <li>
              <i className="fa fa-circle m-r-5"></i>
              {
                "Draft Brand: The brand is not saved and can't use with any campaign."
              }
            </li>
            <li>
              <i className="fa fa-circle m-r-5 active"></i>
              {
                "Active Brand: The brand is active and currently used with active campaign."
              }
            </li>
            <li>
              <i className="fa fa-circle m-r-5 inactive"></i>
              {
                "Inactive Brand: The brand is inactive and currently not used with active campaign."
              }
            </li>
          </ul>
        </div>
      </Card>
    </>
  );
};

export default View;
