import React, {useEffect, useState} from "react";
import Table from "../../../components/general/Table";
import Card from "../../../components/general/Card";
import HeaderActions from "../../../components/general/HeaderBtns";
import _ from "lodash"
import AddCategory from './AddCategory'

const View = (props) => {
  let { categories, deleteCategory, handleCreate } = props;
  console.log(props);

  let [catList , setCatList] = useState([])

  useEffect(() => {
    // Get categories list
    if(!_.isEmpty(categories)) {

      let handledData = _.forEach(categories, cat => {
        cat.parentName = !_.isEmpty(cat.parent) ? cat.parent.name_en : ''
        let catalogList = ''
        _.forEach(cat.catalogs, catalog => catalogList += _.get(catalog, 'name_en') + ', ')

        cat.catalogList = !_.isEmpty(catalogList) ? catalogList.substring(0, catalogList.length - 2) : catalogList // remove the last comma
      })
      setCatList(handledData)

    }
  }, [categories]);
  console.log(catList)

  const headerList = [
    {
      type: "_id",
      isKey: true,
      hidden: true,
      title: "ID",
    },
    {
      sortable: true,
      type: "name_en",
      align: "center",
      title: "Name EN",
    },
    {
      sortable: true,
      type: "name_ar",
      align: "center",
      title: "Name",
    },
    {
      sortable: true,
      type: "parentName",
      align: "center",
      title: "Parent Category",
    },
    {
      sortable: true,
      type: "catalogList",
      align: "center",
      title: "Catalogs",
    },
    {
      sortable: true,
      type: "status",
      align: "center",
      title: "Show in Menu",
    },
    {
      type: "status",
      align: "center",
      title: "Status",
    },
    {
      type: "action",
      sortable: true,
      align: "center",
      export: false,
      title: "Action",
    },
  ];
  return (
    <>
      <Card>
        <HeaderActions
          createAction={false}
          importAction={{ title: "Import", onClick: () => {} }}
          ActionList={{ title: "Action", list: "Delete productation" }}
        >
        <AddCategory {...props} />
        </HeaderActions>
        <div className="contact-table">
          <Table
            data={catList}
            isSelectRow={true}
            EDIT_URL={"ecommerce/edit_category"}
            deleteAction={deleteCategory}
            headers={headerList}
            {...props}
          />
        </div>
        <div className="verified-container m-t-10">
          <ul className="list-unstyled">
            <li>
              <i className="fa fa-circle m-r-5"></i>
              {
                "Draft product: The product is not saved and can't use with any campaign."
              }
            </li>
            <li>
              <i className="fa fa-circle m-r-5 active"></i>
              {
                "Active product: The product is active and currently used with active campaign."
              }
            </li>
            <li>
              <i className="fa fa-circle m-r-5 inactive"></i>
              {
                "Inactive product: The product is inactive and currently not used with active campaign."
              }
            </li>
          </ul>
        </div>
      </Card>
    </>
  );
};

export default View;
