import React, { Component } from "react";
import { getGroups } from "../../../../redux/actions/Ecommerce/GroupAction";
import { editAttribute } from "../../../../redux/actions/Ecommerce/AttributeAction";
import { connect } from "react-redux";
import { attributeTypes } from "../../../../seeder/attributeTypes";
import {
  stringToSelectObject,
  getTypeCase,
} from "../../../../helpers/general-functions";
import SuccessAlert from "../../../../components/general/SuccessAlert";
import ErrorAlert from "../../../../components/general/ErrorAlert";
import _ from "lodash"

import View from "./View";

class index extends Component {
  state = {
    //business_id: "5cc2f00577ebc157b4f1c64c",
    _id: this.props.location.state.attribute._id,
    label: this.props.location.state.attribute.label,
    arabic_label: this.props.location.state.attribute.arabic_label,
    name_en: this.props.location.state.attribute.name_en,
    name_ar: this.props.location.state.attribute.name_ar,
    description_en: this.props.location.state.attribute.description_en,
    description_ar: this.props.location.state.attribute.description_ar,
    desc: this.props.location.state.attribute.desc,
    type: this.props.location.state.attribute.type,
    typeCase: getTypeCase(this.props.location.state.attribute.type),
    group_id: this.props.location.state.attribute.group_id,
    //group_old: this.props.location.state.group_inner_name,
    inner_name: this.props.location.state.attribute.inner_name,
    system_name: this.props.location.state.attribute.system_name,
    options: this.props.location.state.attribute.option,
    is_required: this.props.location.state.attribute.is_required,
    in_form: this.props.location.state.attribute.in_form,
    to_search: this.props.location.state.attribute.to_search,
    class: this.props.location.state.attribute.class,
    errors: null,
  };
  componentWillMount() {
    //const business_id = `business_id: "5cc2f00577ebc157b4f1c64c"`;
    this.props.getGroups("crm");
  }
  onAddOption = (options) => {
    this.setState({ options });
  };

  onClickAddOption(e) {
    e.preventDefault();
  }

  onChange = (e) => {
    this.setState(e);
  };

  onValidate = (e) => {
    e.preventDefault();
    let errors = {};
    let inputs = this.state;
    if (
      !inputs.label ||
      inputs.inner_name === "" ||
      inputs.arabic_label === "" ||
      inputs.type === ""
    ) {
      errors.required = "Pleae fill required inputs";
      this.setState({ errors: errors });
    } else {
      this.editAttributes(e);
    }
  };

  editAttributes = (e) => {
    e.preventDefault();
    console.log("Inside edit ");
    //const attribute = this.getAttribute();
    let attribute = _.omit(this.state, "errors");
    let option = _.isEmpty(_.head(this.state.options).label)? [] : this.state.options

    attribute = {
      ...attribute,
      option: option
    }

    try {
      console.log("Inside Try edit Attribute function", attribute);
      this.props.editAttribute(JSON.stringify(attribute), this.state._id);
      SuccessAlert("success", "Sucessfully Updated");
      this.props.history.goBack(); //redirect to profile attribute
    } catch (error) {
      ErrorAlert("error", `Something went wrong! ${error}`);
    }
  };

  render() {
    let attributeValues = this.props.location.state;
    return (
      <div>
        <View
          {...this.props}
          inputs={attributeValues}
          onChange={this.onChange}
          data={this.state}
          groupList={this.props.groups}
          attributeTypes={attributeTypes}
          editAttribute={this.editAttributes}
          options={this.state.options}
          onAddOption={this.onAddOption}
          onClickAddOption={this.onClickAddOption}
          errors={this.state.errors}
          onValidate={this.onValidate}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  groups: state.ecommerceGroups.items,
  attribute: state.attribute.items,
});

export default connect(mapStateToProps, { getGroups, editAttribute })(index);
