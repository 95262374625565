import React from 'react'
import { FormGroup, Input } from "reactstrap";
export default function View() {
    return (
       
            <div className="search">
            <FormGroup>
              <Input
                className="form-control filter"
                type="text"
                id="search"
                placeholder="Search"
                style={{ zIndex: 0, width: "250px" }}
              />
            </FormGroup>
          </div>
        
    )
}
