import React, { useState } from "react"
import { Button, Form, ModalFooter } from "reactstrap"
import ModalComponent from "../../../../components/general/Modal"
import FormComponent from "../../../../components/general/FormController"
import _ from "lodash"
import { Alert } from "reactstrap"

const View = (props) => {
  const { onChange, data, modal, toggle, errors } = props
  return (
    <>
      <Button className="btn btn-primary btn-cons btn-cons" onClick={toggle}>
        {"Create New"}
      </Button>
      <ModalComponent title="Create New Brand" toggle={toggle} isOpen={modal}>
        <Form onSubmit={props.onSumbit}>
          <FormComponent
            type="text"
            name="name_en"
            label="English Name"
            value={data.name_en}
            updateState={onChange}
            error={_.get(errors, "nameError")}
          />
          {!_.isEmpty(_.get(errors, "nameError")) && (
            <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
          )}

          <FormComponent
            type="text"
            name="name_ar"
            label="Arabic Name"
            value={data.name_ar}
            updateState={onChange}
            error={_.get(errors, "nameError")}
          />
          {!_.isEmpty(_.get(errors, "nameError")) && (
            <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
          )}

          <FormComponent
            type="textarea"
            name="description_en"
            label="English Description"
            value={data.description_en}
            updateState={onChange}
          />

          <FormComponent
            type="textarea"
            name="description_ar"
            label="Arabic Description"
            value={data.description_ar}
            updateState={onChange}
          />

          <FormComponent
            type="file"
            name="logos[]"
            label="Logo"
            value={data.logos}
            updateState={onChange}
          />

          <FormComponent
            type="text"
            name="number_of_digital_assets"
            label="Number of digital assets"
            value={data.number_of_digital_assets}
            updateState={onChange}
          />

          <ModalFooter>
            <Button
              onClick={props.onValidate}
              className="btn btn-primary btn-cons btn-cons">
              {"Create"}
            </Button>
            <Button className="btn btn-white btn-cons" onClick={toggle}>
              {"Cancel"}
            </Button>
          </ModalFooter>
        </Form>
      </ModalComponent>
    </>
  )
}
export default View
