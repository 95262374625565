import React from "react";
import { Route, Switch } from "react-router-dom";

import Role from "../views/UserManagement/Role";
import User from "../views/UserManagement/User";



import NotFound from "../components/general/NotFound";
import QuickNavigation from "../components/layouts/QuickNavigation";


const QuickNavList = [
    {
        title: "Contact List",
        src: "/assets/img/svg/contact-list.svg",
        path: "/crm/contacts",
    },
    {
        title: "Segmentation",
        src: "/assets/img/svg/segmentation.svg",
        path: "/crm/segmentations",
    },
    {
        title: "Tags",
        src: "/assets/img/svg/segmentation.svg",
        path: "/crm/tags",
    },
    {
        title: "Automated Rule",
        src: "/assets/img/svg/automated-rules.svg",
        path: "/#",
    },
    {
        title: "Profile Attribute",
        src: "/assets/img/svg/profile-attribute.svg",
        path: "/crm/profile_attribute",
    },
    {
        title: "Import / Export",
        src: "/assets/img/svg/import-export.svg",
        path: "/crm/import_export",
    },
];
const userManageRoutes = () => {
    const currentPath = "/user";
    return (
        <div>
            <QuickNavigation quickNavs={QuickNavList} />
            <Switch>
                {/* User Managment Routes */}

                <Route path={`${currentPath}/users`} component={User} />

                <Route path={`${currentPath}/roles`} component={Role} />

                <Route component={NotFound} />
            </Switch>
        </div>
    )
}
export default userManageRoutes;
