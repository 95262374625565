import React, { PropTypes, useState, useEffect } from "react";
import View from "./View";
import { connect } from "react-redux";
import _ from "lodash";
import {
  getconfigAttributes,
  editConfigProductAttributes,
} from "redux/actions/Ecommerce/ConfigurableAttributeAction";

const Index = (props) => {
  // To convert selected Attribute to specific structure
  const preparedSelectedAttribute = (selectedAttributes) => {
    selectedAttributes = selectedAttributes.map((attr) => {
      if (attr.attribute_id) {
        attr.attribute_id.options.map((opt) => {
          if (attr.options.includes(opt._id)) {
            opt.checked = true;
          }
        });
        attr = attr.attribute_id;
      }
      return attr;
    });
    return selectedAttributes;
  };

  // States
  const [currentStep, setcurrentStep] = useState(1);
  const [selectedAttributes, setSelectedAttribute] = useState(
    preparedSelectedAttribute(props.data.configurable_attributes)
  );

  const [selectedAttributesOptions, setSelectedAttributesOptions] = useState(
    []
  );

  let isNew = _.isEmpty(props.variants) ? true : false;
  const [state, setState] = useState({
    isUpdate: isNew,
  });

  const [variants, setVariants] = useState(props.variants);

  //Functions
  useEffect(() => {
    // Get list of the config Attribute
    props.getconfigAttributes();
  }, []);

  const handleClick = (setp) => {
    setcurrentStep(setp);
  };

  const removeVariant = (index, e) => {
    e.preventDefault();
    let products = variants.slice();
    products.splice(index, 1);
    setVariants(products);
  };

  const editConfigProductAttribute = () => {
    let configProductAttributes = selectedAttributesOptions.map((attr) => ({
      attribute_id: attr._id,
      product_id: props.data._id,
      options: attr.options.map(({ _id }) => _id),
    }));
    props.editConfigProductAttributes(
      JSON.stringify(configProductAttributes),
      props.data._id
    );
  };

  const submit = () => {
    console.log("Selected Attribute Option", selectedAttributesOptions);
    if (selectedAttributesOptions.length !== 0) {
      editConfigProductAttribute();
    }
    props.setVarinats(variants);
  };

  return (
    <div>
      <View
        {...props.data}
        configAttributes={props.configAttributes}
        currentStep={currentStep}
        selectedAttributes={selectedAttributes}
        updateSelectedAttribute={setSelectedAttribute}
        handleClick={handleClick}
        handleSubmit={submit}
        selectedAttributesOptions={selectedAttributesOptions}
        updateSelectedAttributesOption={setSelectedAttributesOptions}
        deleteProduct={removeVariant}
        variants={variants}
        {...state}
        updateState={setState}
        updateVariants={setVariants}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  configAttributes: state.configurableAttributes.items,
});

export default connect(mapStateToProps, {
  getconfigAttributes,
  editConfigProductAttributes,
})(Index);
