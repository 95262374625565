import React, { useEffect, useState, useRef } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { capitalize } from "../../../helpers/general-functions";
import { restfulUrl } from 'context/url';
import _ from "lodash";
import "../../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css";

import moment from "moment";
//import Delete from "../../ContactProfile/ContactDelete"
import Delete from "../../general/Delete";

const Index = (props) => {
  const { data, isSelectRow, EDIT_URL, deleteAction, headers } = props;
  const [cellSize, updateSize] = useState("100%");
  const refTable = useRef();

  useEffect(() => {
    window.addEventListener("resize", () => _evaluateMode());
  }, []);

  const _evaluateMode = () => {
    let windowWidth = window.innerWidth;
    if (windowWidth <= 768) {
      updateSize("150px");
    }
  };

  const getCellFormat = (type) => {
    switch (type) {
      case "phone":
      case "email":
        return formatWithIcon;

      case "last_login":
        return formatLastLogin;

      case "start_date":
        return formatLastLogin;

      case "end_date":
        return formatLastLogin;

      case "status":
        return formatStatus;
      case "media":
        return formatMedia;
      case "action":
        return formatAction;

      default:
        return formatCell;
    }
  };

  const renderShowsTotal = (start, to, total) => {
    return (
      <p className="text-grey">
        Showing {start}-{to} of {total} Results
      </p>
    );
  };

  let formatWithIcon = (cell, row, option) => {
    if (cell) {
      return (
        <span onClick={() => handleEdit(row, row._id)}>
          <i
            className={
              `fa ${option.className} m-r-5 ` +
              (row[option.verified] ? "verified" : "")
            }
          />
          {cell}
        </span>
      );
    }
    return <span onClick={() => handleEdit(row, row._id)}>{"N/A"}</span>;
  };

  let formatStatus = (cell, row) => {
    if (cell) {
      return (
        <span
          onClick={() => (!props.noEdit ? handleEdit(row, row._id) : {})}
          className={`badge ` + cell + `-bg status-badge`}
        >
          {capitalize(cell)}
        </span>
      );
    }
  };

  let formatLastLogin = (cell, row) => {
    return (
      <span onClick={() => handleEdit(row, row._id)}>
        {cell ? moment(cell).format("MMM d, YYYY") : "N/A"}
      </span>
    );
  };

  let formatAction = (cell, row, opt, index) => {
    console.log(cell, row);
    return (
      <div>
        {!props.noEdit && (
          <i
            onClick={() => handleEdit(row, row._id)}
            className="fa fa-pencil-square-o m-r-5"
          />
        )}
        <Delete
          data={row.name ? row.name : row.name_en}
          onSubmit={deleteAction}
          customerID={props.deleteByRowIndex ? index : row._id}
        />
      </div>
    );
  };
 

  let formatMedia = (cell, row) => {
      return (
        <div class="pb-4" style={{ maxWidth: "80px", margin:"auto" }}>
         <img src={ Array.isArray(cell)? restfulUrl + "/" + cell[0]: restfulUrl + "/" + cell} width="100%"/>
        </div>
      );
  }; 


  let formatCell = (cell, row) => {
    return (
      <span onClick={() => (!props.noEdit ? handleEdit(row, row._id) : {})}>
        {cell ? cell : "N/A"}
      </span>
    );
  };

  let filterColumn = (type, options = null) => {
    if (type === "all") {
      // refTable.current.cleanFiltered();
      options = options.map((option) => {
        return `^${option}|`;
      });

      refTable.current.handleFilterData({
        status: { type: "RegexFilter", value: `${options}+$` },
      });
    } else {
      // refTable.current.handleFilterData({ status: [type] });
      refTable.current.handleFilterData({
        status: { type: "RegexFilter", value: `^${type}$` },
      });
    }
  };

  const handleEdit = (row, id) => {
    if (props.handleEdit) props.handleEdit(row, id);
    if (props.history)
      props.history.push({
        pathname: `/${EDIT_URL}`,
        state: {
          id: id,
          item: row,
        },
      });
  };

  const handleOnSelect = (row, isSelect, opt, rowIndex) => {
    if (props.handleOnSelect) {
      props.handleOnSelect(row, isSelect, rowIndex);
    }
  };

  const options = {
    paginationShowsTotal: renderShowsTotal,
  };

  let selectRowProp = {
    mode: "checkbox",
    clickToSelect: true,
    bgColor: "#f3f3f3",
    onSelect: handleOnSelect,
    selected: props.selected,
  };

  let cellEditProp = {
    mode: "click",
    blurToSave: true,
  };
 console.log("Inside Table", data)
  return (
    <>
      {!props.noFilter && (
        <div className="btn-group">
          <button
            className="btn btn-white dropdown-toggle btn-cons"
            data-toggle="dropdown"
          >
            {"Filter"}
            <span className="caret" />
          </button>
          <ul className="dropdown-menu">
            <li
              className="pointer"
              onClick={() => filterColumn("all", ["Active", "Inactive"])}
            >
              {"Status: All"}
            </li>
            <li className="pointer" onClick={() => filterColumn("Active")}>
              {"Status: Active"}
            </li>
            <li className="pointer" onClick={() => filterColumn("Inactive")}>
              {"Status: Inactive"}
            </li>
          </ul>
        </div>
      )}
      <BootstrapTable
        data={data}
        selectRow={isSelectRow ? selectRowProp : {}}
        options={options}
        ref={refTable}
        hover
        pagination
        condensed
        cellEdit={props.cellEdit ? cellEditProp : false}
        containerClass={"tblDetails"}
        search={props.noSearch ? false : true}
        multiColumnSearch
      >
        {_.map(headers, (header, index) => (
          <TableHeaderColumn
            key={index}
            isKey={header.isKey ? true : false}
            hidden={header.hidden ? true : false}
            dataSort={header.sortable ? true : false}
            dataField={header.type}
            dataAlign={header.align}
            editable={header.editable ? header.editable : false}
            filter={header.filter ? header.filter : ""}
            dataFormat={getCellFormat(header.type)}
            formatExtraData={
              header.formatExtraData ? header.formatExtraData : ""
            }
            export={header.export ? header.export : true}
            width={cellSize}
          >
            {header.title}
          </TableHeaderColumn>
        ))}
      </BootstrapTable>
    </>
  );
};

export default Index;
