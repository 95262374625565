import React, { useState } from "react";
import Table from "../../../components/general/Table";
import Card from "../../../components/general/Card";
import HeaderActions from "../../../components/general/HeaderBtns";
import AddProduct from "./Add";

const View = (props) => {
  let { products, deleteProduct, handleCreate } = props;
  let [isCard, setIsCard] = useState(false);

  const headerList = [
    {
      type: "_id",
      isKey: true,
      hidden: true,
      title: "ID",
    },
    {
      sortable: true,
      type: "sku",
      align: "center",
      title: "SKU",
    },
    {
      sortable: true,
      type: "name_ar",
      align: "center",
      title: "Name",
    },
    {
      sortable: true,
      type: "description_ar",
      align: "center",
      title: "Description",
    },
    {
      sortable: true,
      type: "type",
      align: "center",
      title: "Type",
    },
    {
      sortable: true,
      type: "created_date",
      align: "center",
      title: "Creation Date",
    },
    {
      type: "status",
      align: "center",
      title: "Status",
    },
    {
      type: "action",
      sortable: true,
      align: "center",
      export: false,
      title: "Action",
    },
  ];

  return (
    <>
      <Card>
        {/* Product Cards */}
        {isCard ? (
          <>
            <div className="row">
              {products.map((product) => {
                return (
                  <div className="col-lg-3 col-sm-4 col-6 mb-4">
                    <Card
                      image={product.media ? product.media[0] : null}
                      title={product.name_en}
                      subtitle={product.description_en}
                      button={{ title: "Check Now", url: "edit_product" }}
                    />
                  </div>
                );
              })}
            </div>
            <div className="row mt-4">
              <div className="col-12">{props.pagination}</div>
            </div>
          </>
        ) : (
          <>
            {/* Product table */}
            <HeaderActions
              createAction={false}
              importAction={{ title: "Import", onClick: () => {} }}
              ActionList={{ title: "Action", list: "Delete productation" }}
            >
              <AddProduct /* modal="true" */ />
            </HeaderActions>
            <div className="contact-table">
              <Table
                data={products}
                isSelectRow={true}
                EDIT_URL={"ecommerce/edit_product"}
                deleteAction={deleteProduct}
                headers={headerList}
                {...props}
              />
            </div>
            <div className="verified-container m-t-10">
              <ul className="list-unstyled">
                <li>
                  <i className="fa fa-circle m-r-5"></i>
                  {
                    "Draft product: The product is not saved and can't use with any campaign."
                  }
                </li>
                <li>
                  <i className="fa fa-circle m-r-5 active"></i>
                  {
                    "Active product: The product is active and currently used with active campaign."
                  }
                </li>
                <li>
                  <i className="fa fa-circle m-r-5 inactive"></i>
                  {
                    "Inactive product: The product is inactive and currently not used with active campaign."
                  }
                </li>
              </ul>
            </div>
          </>
        )}
      </Card>
    </>
  );
};

export default View;
