import {
  ADD_ECOM_ATTRIBUTE,
  EDIT_ECOM_ATTRIBUTE,
  GET_ECOM_ATTRIBUTES,
  FIND_ECOM_ATTRIBUTE,
  DELETE_ECOM_ATTRIBUTE,
} from "./types";
import  fetchAPIClass  from "../fetchAPI";
import { restfulUrl , API_URL} from "../../../context/url";

export const getAttributes = () => (dispatch) => {
  console.log("inside get Attribute E");
  let url = `${API_URL}/ecommerce/attribute/all`;
  try {
    const api = new fetchAPIClass(url, "GET", null, GET_ECOM_ATTRIBUTES);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const getAttribute = (attributeId) => (dispatch) => {
  let url = `${API_URL}/ecommerce/attribute/find/${attributeId}`;
  try {
    const api = new fetchAPIClass(url, "GET", null, FIND_ECOM_ATTRIBUTE);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const getAttributeBy = (attributeKey, attributeVal) => (dispatch) => {
  let url = `${API_URL}/ecommerce/attribute/findBy/${attributeKey}/${attributeVal}`;
  console.log("url", url);
  try {
    const api = new fetchAPIClass(url, "GET", null, FIND_ECOM_ATTRIBUTE);
    api.fetchAPI(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const addAttribute = (inputs) => (dispatch) => {
  let requestBody = inputs;
  let url = `${API_URL}/ecommerce/attribute/add`;
  console.log("InSide Add attribute Action", requestBody);
  try {
    const api = new fetchAPIClass(url, "POST", requestBody, ADD_ECOM_ATTRIBUTE);
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

export const editAttribute = (inputs, attributeId) => (dispatch) => {
  let requestBody = inputs;
  let url = `${API_URL}/ecommerce/attribute/edit/${attributeId}`;
  console.log("InSide Add attribute Action", requestBody, "id", attributeId);
  try {
    const api = new fetchAPIClass(url, "PUT", requestBody, EDIT_ECOM_ATTRIBUTE);
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};

export const deleteAttribute = (attributeId) => (dispatch) => {
  let url = `${API_URL}/ecommerce/attribute/delete/${attributeId}`;
  console.log(url);
  try {
    const api = new fetchAPIClass(url, "DELETE", null, DELETE_ECOM_ATTRIBUTE);
    let response = api.fetchAPI(dispatch);
    console.log(response);
  } catch (err) {
    console.log(err);
  }
};
