import {
    ADD_CATEGORY,
    EDIT_CATEGORY,
    GET_CATEGORIES,
    FIND_CATEGORY,
    DELETE_CATEGORY,
  } from "./types"
  import fetchAPIClass  from "../fetchAPI"
  import { restfulUrl , API_URL} from "../../../../src/context/url"
  
  export const getCategories = () => (dispatch) => {
    let url = `${API_URL}/ecommerce/category/all`
    try {
      const api = new fetchAPIClass(url, "GET", null, GET_CATEGORIES)
      api.fetchAPI(dispatch)
    } catch (err) {
      console.log(err)
    }
  }
  
  export const addCategory = (inputs) => (dispatch) => {
    let requestBody = inputs
    let url = `${API_URL}/ecommerce/category/add`
  console.log(requestBody)
    try {
      const api = new fetchAPIClass(url, "POST", requestBody, ADD_CATEGORY)
      let response = api.fetchAPI(dispatch)
      console.log(response)
    } catch (err) {
      console.log(err)
    }
  }
  
  export const deleteCategory = (categoryId) => (dispatch) => {
    let url = `${API_URL}/ecommerce/category/delete/${categoryId}`
    console.log(categoryId)
    try {
      const api = new fetchAPIClass(url, "DELETE", null, DELETE_CATEGORY)
      api.fetchAPI(dispatch)
    } catch (err) {
      console.log(err)
    }
  }

  export const editCategory = (inputs , categoryId) => (dispatch) => {
    let requestBody = inputs
    let url = `${API_URL}/ecommerce/category/edit/${categoryId}`
  console.log(requestBody)
  console.log(categoryId)

    try {
      const api = new fetchAPIClass(url, "POST", requestBody, EDIT_CATEGORY)
      let response = api.fetchAPI(dispatch)
      console.log(response)
    } catch (err) {
      console.log(err)
    }
  }