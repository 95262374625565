import React, { useState } from "react";
import { Button, Form, ModalFooter } from "reactstrap";
import ModalComponent from "../../../../components/general/Modal";
import FormComponent from "../../../../components/general/FormController";
import _ from "lodash";
import { Alert } from "reactstrap";

const View = (props) => {
  const {
    onChange,
    data,
    genderOptions,
    modal,
    toggle,
    errors,
    customerTypes,
  } = props;

  return (
    <>
      <Button className="btn btn-primary btn-cons btn-cons" onClick={toggle}>
        {"Create New"}
      </Button>
      <ModalComponent title="Test" toggle={toggle} isOpen={modal}>
        <Form onSubmit={props.onSumbit}>
          <FormComponent
            type="text"
            name="name"
            label="Name"
            value={data.name}
            updateState={onChange}
            error={_.get(errors, "nameError")}
          />
          {_.get(errors, "nameError") !== "" && (
            <Alert color="danger"> {_.get(errors, "nameError")} </Alert>
          )}

          <div className="flex-grow-first-child d-flex justify-content-between align-items-center">
            <FormComponent
              type="email"
              name="email"
              label="Email"
              classFormGroup="flex-grow-1"
              value={data.email}
              updateState={onChange}
              error={_.get(errors, "emailError")}
            />
            <FormComponent
              type="switch"
              name="email_opt_in"
              label="opt in"
              id="email_opt_in"
              classFormGroup="d-flex mb-0"
              classLabel="mr-3 mb-0"
              value={data.email_opt_in}
              updateState={onChange}
            />
          </div>
          {_.get(errors, "emailError") !== "" && (
            <Alert color="danger"> {_.get(errors, "emailError")} </Alert>
          )}
          <div className="flex-grow-first-child d-flex justify-content-between align-items-center">
            <FormComponent
              type="phone"
              name="phone"
              label="Mobile"
              classFormGroup="flex-grow-1"
              value={data.phone}
              updateState={onChange}
              error={_.get(errors, "phoneError")}
            />
            <FormComponent
              type="switch"
              name="phone_opt_in"
              label="opt in"
              id="phone_opt_in"
              classFormGroup="d-flex mb-0"
              classLabel="mr-3 mb-0"
              value={data.phone_opt_in}
              updateState={onChange}
            />
          </div>
          {_.get(errors, "phoneError") !== "" && (
            <Alert color="danger"> {_.get(errors, "phoneError")} </Alert>
          )}
          <FormComponent
            type="select"
            options={_.map(customerTypes, (type) => ({
              label: _.get(type, "name_en"),
              value: _.get(type, "_id"),
            }))}
            name="customer_type"
            label="Customer Type"
            value={data.customer_type}
            valueKey="value"
            labelKey="label"
            updateState={onChange}
          />
          {genderOptions ? (
            <FormComponent
              type="select"
              options={genderOptions.data}
              name="gender"
              label="Gender"
              value={data.gender}
              valueKey="value"
              labelKey="label"
              updateState={onChange}
            />
          ) : (
            "Loading.."
          )}
          <FormComponent
            type="date"
            name="birth_date"
            label="Birthday"
            value={data.birth_date}
            updateState={onChange}
          />
          <ModalFooter>
            <Button
              //type="submit"
              onClick={props.onValidate}
              className="btn btn-primary btn-cons btn-cons"
            >
              {"Create"}
            </Button>
            <Button className="btn btn-white btn-cons" onClick={toggle}>
              {"Cancel"}
            </Button>
          </ModalFooter>
        </Form>
      </ModalComponent>
    </>
  );
};
export default View;
