//import React, { useState, useEffect, useContext } from "react";
import React, { Component } from "react";
import View from "./View";
import { connect } from "react-redux";
import { addCategory , getCategories } from "../../../../redux/actions/Ecommerce/CategoryAction";
import SuccessAlert from "../../../../components/general/SuccessAlert";
import ErrorAlert from "../../../../components/general/ErrorAlert";
import _ from "lodash"


class index extends Component {

  state = this.initialState

    onChange = input => {
        this.setState(input);
    }

  get initialState() {
    return {
      business_id: "5cc2f00577ebc157b4f1c64c",
      name_en: "",
      name_ar: "",
      parent: null,
      catalogs: [],
      errors: {
        nameError: '',
        nameArError: '',
        parentError: ''
      },
      modal: this.props.isFromEdit ?? false
    }
  }


  reset() {
    this.setState(this.initialState);
  }

  toggle = () => {
    this.setState({ modal: !this.state.modal })
    //this.reset()
  }

  onValidate = (e) => {
    e.preventDefault()
      let errors = {
          nameError: '',
          nameArError: '',
          parentError: '',
      }
    let inputs = this.state


    if (inputs.name_en === "" ||  inputs.name_ar === "") {
      errors.nameError = "name Englinsh & Arabic are required !"
    }

    if (errors.nameError === '') {
      this.addCategory(e)
    }
    else {
      //setErrors(errors)
      this.setState({ errors: errors })
    }
  }

  addCategory = e => {
    e.preventDefault()
    console.log(this.state)
    let customer = `{
    "business_id": "${this.state.business_id}",
    "parent":"${this.state.parent}",
    "name_en": "${this.state.name_en}",
    "name_ar": "${this.state.name_ar}",
    "catalogs": ["${this.state.catalogs}"]
    }`
//    "path": "${this.state.parent}",

    try {
      let response = this.props.addCategory(JSON.stringify(this.state))

      SuccessAlert("success", "Sucessfully Added");
      console.log(response);
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
    this.reset()
    this.toggle()
  };

  render() {
      console.log(this.props.categories)

    return (
      <View
        data={this.state}
        categories={this.props.categories}
        catalogs={this.props.catalogs}
        onChange={this.onChange}
        onSumbit={this.addCategory}
        onValidate={this.onValidate}
        errors={this.state.errors}
        modal={this.state.modal}
        toggle={this.toggle}
        isFromEdit={this.props.isFromEdit}
      />
    )
  }
}

const mapStateToProps = state => ({
  categories: state.categories.items
});

export default connect(mapStateToProps, { addCategory , getCategories})(index);
