import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { DropdownMenu, DropdownItem } from "reactstrap";

export default class Sidebar extends Component {
  render() {
    return (
      <div>
        <div>
          {/* BEGIN SIDEBAR */}
          <div className="page-sidebar" id="main-menu">
            {/* BEGIN MINI-PROFILE */}
            <div
              className="page-sidebar-wrapper scrollbar-dynamic"
              id="main-menu-wrapper"
            >
              {/* BEGIN SIDEBAR MENU */}
              <p className="menu-title sm">BROWSE</p>
              <ul>
                <li className="start">
                  <NavLink to="/">
                    <i className="material-icons">home</i>
                    <span className="title">Dashboard</span>
                    <span className="selected" />
                  </NavLink>
                </li>
                <li>
                  <a href="#">
                    {" "}
                    <i className="material-icons">visibility</i>{" "}
                    <span className="title">{"Widgets"}</span>{" "}
                    <span className="label label-important bubble-only pull-right " />
                  </a>
                </li>
                <li>
                  <NavLink to="/crm/contacts" activeClassName="clicked">
                    {" "}
                    <i className="material-icons">contacts</i>{" "}
                    <span className="title">{"Contact List"}</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/setting/index" activeClassName="clicked">
                    {" "}
                    <i className="material-icons">settings</i>{" "}
                    <span className="title">{"Settings"}</span>{" "}
                    <span className=" arrow" />{" "}
                  </NavLink>
                  <ul className="sub-menu">
                    <li>
                      {" "}
                      <a href="#">{"Profile"} </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="#">{"Inbox"}</a>{" "}
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="javascript:;">
                    {" "}
                    <i className="material-icons">record_voice_over</i>{" "}
                    <span className="title">{"Marketing Automation"}</span>{" "}
                    <span className="arrow" />{" "}
                  </a>
                  <ul className="sub-menu">
                    <li>
                      {" "}
                      <a href="#"> {"sub menu 1"} </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="#"> {"sub menu 2"} </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="#"> {"sub menu 3"} </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="#"> {"sub menu 4"} </a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="#"> {"sub menu 5"} </a>{" "}
                    </li>
                  </ul>
                </li>
                <li>
                  <NavLink to="/ecommerce" activeClassName="clicked">
                    {" "}
                    <i className="material-icons">shopping_cart</i>
                    <span className="title">{"E-Commerce"}</span>{" "}
                    <span className=" arrow" />{" "}
                  </NavLink>

                  <DropdownMenu className="sub-menu">
                    <DropdownItem>
                      {" "}
                      <a href="#"> {"Products"} </a>{" "}
                    </DropdownItem>
                    <DropdownItem>
                      {" "}
                      <a href="#"> {"Orders"} </a>{" "}
                    </DropdownItem>
                    <DropdownItem>
                      {" "}
                      <a href="#"> {"Page Builder"} </a>{" "}
                    </DropdownItem>
                    <DropdownItem>
                      {" "}
                      <a href="#"> {"Products"} </a>{" "}
                    </DropdownItem>
                  </DropdownMenu>
                </li>
                <li>
                  <NavLink to="/order/index" activeClassName="clicked">
                    {" "}
                    <i className="material-icons">store</i>
                    <span className="title">{"Order Management"}</span>{" "}
                    <span className=" arrow" />{" "}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/user/roles" activeClassName="clicked">
                    {" "}
                    <i className="material-icons">store</i>
                    <span className="title">{"User Management"}</span>{" "}
                    <span className=" arrow" />{" "}
                  </NavLink>
                </li>
                <li>
                  <a href="javascript:;">
                    {" "}
                    <i className="material-icons">panorama</i>{" "}
                    <span className="title">{"Gallery"}</span>{" "}
                  </a>
                </li>
                <li>
                  <a href="javascript:;">
                    {" "}
                    <i className="material-icons">bar_chart</i>{" "}
                    <span className="title">{"Analysis"}</span>{" "}
                    <span className=" arrow" />{" "}
                  </a>
                  <ul className="sub-menu">
                    <li>
                      {" "}
                      <a href="#">{"Quick view"}</a>{" "}
                    </li>
                    <li>
                      {" "}
                      <a href="#">{"Full details"}</a>{" "}
                    </li>
                  </ul>
                </li>
              </ul>
              <div className="clearfix" />
              {/* END SIDEBAR MENU */}
            </div>
          </div>
          <a href="#" className="scrollup">
            Scroll
          </a>
          <div className="footer-widget">
            <div className="progress transparent progress-small no-radius no-margin">
              <div
                className="progress-bar progress-bar-success animate-progress-bar"
                data-percentage="79%"
                style={{ width: "79%" }}
              />
            </div>
            <div className="pull-right">
              <div className="details-status">
                {" "}
                <span
                  className="animate-number"
                  data-value={86}
                  data-animation-duration={560}
                >
                  86
                </span>
                %{" "}
              </div>
              <a href="lockscreen.html">
                <i className="material-icons">power_settings_new</i>
              </a>
            </div>
          </div>
          {/* END SIDEBAR */}
        </div>
      </div>
    );
  }
}
