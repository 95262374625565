import React from "react";
import ReactSVG from "react-svg";
import { Input } from "reactstrap";
import Form from "../../../FormControllerList";
import Select from "react-select";

const index = (props) => {
  // let type =
  //   props.condition.attribute.type === "radio"
  //     ? "select"
  //     : props.condition.attribute.type;
  const getConditionLayout = () => {
    console.log("Conition", props.logic.logic_list);
    return (
      <div className="logic-container">
        <div className="logic-parent">
          <div className="logic">
            <div className="logic-drop">
              <a
                onClick={() => props.handleRemoveCondition(props.index)}
                className="drop"
              >
                <ReactSVG src="/assets/img/svg/cancel.svg" />
              </a>
            </div>
            <div className="logic-fields">
              <div className="attribute">
                <Form
                  {...props}
                  type={"select"}
                  options={props.attributes}
                  name="attribute"
                  target={props.logic.logic_list[props.index]}
                  targetName={props.targetName? props.targetName : "logic"}
                  storeAllOptionInfo={true}
                  reset={["value", "operator"]}
                  value={
                    props.condition.attribute
                      ? props.condition.attribute.inner_name
                      : ""
                  }
                  valueKey="inner_name"
                  labelKey="label"
                  updateState={props.updateState}
                />
              </div>
              <div className="operator">
                <Form
                  {...props}
                  type={"select"}
                  options={
                    props.condition.attribute
                      ? props.condition.attribute.operator
                      : []
                  }
                  name={"operator"}
                  target={props.logic.logic_list[props.index]}
                  targetName={props.targetName? props.targetName : "logic"}
                  value={props.condition.operator}
                  valueKey="value" // for options
                  labelKey="label" // for options
                  updateState={props.updateState}
                />
              </div>
              <div className="value">
                <Form
                  {...props}
                  type={
                    props.condition.attribute.type === "radio" ||
                    props.condition.attribute.type === "singleCheckbox"
                      ? "select"
                      : props.condition.attribute.type
                  }
                  options={
                    props.condition.attribute.type === "singleCheckbox"
                      ? [
                          { label: "True", value: "true" },
                          { label: "False", value: "false" },
                        ]
                      : props.condition.attribute.data
                  }
                  name="value"
                  value={props.condition.value}
                  default={props.condition.value}
                  target={props.logic.logic_list[props.index]}
                  targetName={props.targetName? props.targetName : "logic"}
                  valueKey="value"
                  labelKey="label"
                  updateState={props.updateState}
                />
              </div>
            </div>
          </div>
          {(props.index + 1 !== props.logic.logic_list.length ||
            (props.condition.child &&
              props.condition.child.logic_list.length !== 0)) &&
            props.switcherLayout(props.logic.operator, null)}
        </div>
        {props.condition.child &&
          props.condition.child.logic_list.length !== 0 &&
          getCombinationLayout(props.logic, props.index)}
      </div>
    );
  };

  const getCombinationLayout = (logic, index) => {
    return (
      <div className="logic-child">
        <div className="logic-child-container">
          <p className="main-operator-container">
            <div className="logic-drop">
              <a
                onClick={() => props.handleRemoveCondition(props.index, "all")}
                className="drop"
              >
                <ReactSVG src="/assets/img/svg/cancel.svg" />
              </a>
            </div>
            <span>{"IF Contact Match"}</span>
            <Form
              {...props}
              type={"select"}
              options={[
                { label: "All", value: "all" },
                { label: "Any", value: "any" },
              ]}
              target={props.logic.logic_list[props.index].child}
              targetName={props.targetName? props.targetName : "logic"}
              name={"operator"}
              valueKey="value"
              labelKey="label"
              value={props.logic.logic_list[props.index].child.operator}
              isClearable={false}
              isSearchable={false}
              updateState={props.updateState}
            />
            <span>{"of The Following Conditions"}</span>
          </p>
          {props.condition.child.logic_list.map((logicChild, indexChild) => {
            return (
              <div className="logic-container">
                <div className="logic">
                  <div className="logic-drop">
                    <a
                      onClick={() =>
                        props.handleRemoveCondition(props.index, indexChild)
                      }
                      className="drop"
                    >
                      <ReactSVG src="/assets/img/svg/cancel.svg" />
                    </a>
                  </div>
                  <div className="logic-fields">
                    <div className="attribute">
                      <Form
                        {...props}
                        type={"select"}
                        options={props.attributes}
                        name="attribute"
                        target={
                          props.logic.logic_list[props.index]["child"]
                            .logic_list[indexChild]
                        }
                        targetName={props.targetName? props.targetName : "logic"}
                        storeAllOptionInfo={true}
                        reset={["value", "operator"]}
                        value={logicChild.attribute.inner_name}
                        valueKey="inner_name"
                        labelKey="label"
                        updateState={props.updateState}
                      />
                    </div>

                    <div className="operator">
                      <Form
                        {...props}
                        type={"select"}
                        options={logicChild.attribute.operator}
                        name={"operator"}
                        target={
                          props.logic.logic_list[props.index]["child"]
                            .logic_list[indexChild]
                        }
                        targetName={props.targetName? props.targetName : "logic"}
                        value={logicChild.operator}
                        valueKey="value" // for options
                        labelKey="label" // for options
                        updateState={props.updateState}
                      />
                    </div>
                    <div className="value">
                      <Form
                        {...props}
                        type={
                          logicChild.attribute.type === "radio"
                            ? "select"
                            : logicChild.attribute.type
                        }
                        options={logicChild.attribute.data}
                        name="value"
                        value={logicChild.value}
                        default={logicChild.value}
                        target={
                          props.logic.logic_list[props.index]["child"]
                            .logic_list[indexChild]
                        }
                        targetName={props.targetName? props.targetName : "logic"}
                        valueKey="value"
                        labelKey="label"
                        updateState={props.updateState}
                      />
                    </div>
                  </div>
                </div>

                {indexChild + 1 !== props.condition.child.logic_list.length &&
                  props.switcherLayout(props.condition.child.operator, index)}
              </div>
            );
          })}
        </div>

        {props.index + 1 !== props.logic.logic_list.length &&
          props.switcherLayout(props.logic.operator, null)}
      </div>
    );
  };

  return getConditionLayout();
};

export default index;
