import {
  ADD_PRODUCT,
  EDIT_PRODUCT,
  GET_PRODUCTS,
  SET_PRODUCT,
  FIND_PRODUCT,
  DELETE_PRODUCT,
  EDIT_PRODUCT_MASTER
} from "../../actions/Ecommerce/types";

const initialState = {
  item: null,
  items: null,
  masterProducts:null,
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_PRODUCT:
    case EDIT_PRODUCT:
    case FIND_PRODUCT:
    case SET_PRODUCT:
    case DELETE_PRODUCT:
      return {
        ...state,
        item: action.payload,
      };
    case GET_PRODUCTS:
      return {
        ...state,
        items: action.payload,
        loading: false,
      };
      case EDIT_PRODUCT_MASTER:
        return {
          ...state,
          masterProducts: action.payload,
        };
    default:
      return state;
  }
}
