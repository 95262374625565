import React, { PropTypes } from "react";
import Card from "../../../../components/general/Card";
import HeaderInfo from "../../../../components/general/PageDetails/HeaderInfo";
import GeneralInfo from "../../../../components/general/PageDetails/Info";
import CollapseList from "../../../../components/general/PageDetails/CollapseList";
import _ from "lodash";
import { Row, Col, Button } from "reactstrap";
import PriceComponent from "./Price";
import CategoryComponent from "./ProductCategory";
import Inventory from "./ProductInventory";
import Variant from "../../Variant";
import Shipping from "./Shipping";
import group from "redux/reducers/group";
const View = (props) => {
  const {
    data,
    deleteProduct,
    editProduct,
    groupAttributes,
    products_catalogs,
  } = props;

  let generalInfoInput = [
    {
      type: "switch",
      name: "status",
      id: "status",
      label: "Product Status",
      classFormGroup: "d-flex",
      classLabel: "mr-3",
      value: _.toLower(data.status) === "active" ? true : false,
      updateState: true,
    },
    {
      type: "text",
      name: "_id",
      label: "Product ID",
      value: data["_id"],
      default: data["_id"],
      disabled: true,
    },
    {
      type: "select",
      options: props.productTypes,
      name: "type",
      label: "Product Type",
      value: data["type"],
      default: data["type"],
    },
    {
      type: "groupCheckbox",
      options: props.catalogs,
      name: "catalogs",
      label: "Catalog",
      valueKey: "_id",
      labelKey: "name_en",
      value: data["catalogs"],
      default: data["catalogs"],
    },
  ];

  const staticGroups = [
    {
      id: 0,
      label: "Product Information",
      collapseCase: "ListAttribute",
      open: true,
      attributes: [
        {
          label: "SKU",
          name: "sku",
          type: "text",
        },
        {
          label: "English Name",
          name: "name_en",
          type: "text",
        },
        {
          label: "Arabic Name",
          name: "name_ar",
          system_name: "name_ar",
          type: "text",
        },
      ],
    },
    {
      id: 1,
      label: "Description",
      collapseCase: "ListAttribute",
      open: false,
      attributes: [
        {
          label: "English Description",
          name: "description_en",
          type: "textarea",
        },
        {
          label: "Arabic Description",
          name: "description_ar",
          type: "textarea",
        },
      ],
    },
    {
      id: 2,
      label: "Media",
      collapseCase: "Media",
      open: false,
      name: "media",
      multiple: true,
    },
    {
      id: 3,
      label: "Price",
      open: false,
    },
    {
      id: 4,
      label: "Category",
      collapseCase: "category",
      open: false,
    },
    /*{
      id: 5,
      label: "Inventory",
      collapseCase: "inventory",
      open: false,
    },*/
    {
      id: 5,
      label: "Inventory",
      collapseCase: "table",
      open: false,
      items: props.branches,
    },
    // {
    //   id: 6,
    //   label: "Filter",
    //   collapseCase: "filter",
    //   open: false,
    // },
    {
      id: 7,
      label: "Variants",
      collapseCase: "variant",
      open: false,
    },
    {
      id: 8,
      label: "Promotion",
      collapseCase: "promotion",
      open: false,
    },
    // {
    //   id: 9,
    //   label: "Marketing",
    //   collapseCase: "ListAttribute",
    //   open: false,
    // },
    {
      id: 10,
      label: "Related Product, Up-Sells and Cross-Sells",
      collapseCase: "logic",
      open: false,
    },
  ];

  return (
    <Card name="Product Details">
      {/* Headr  */}
      <HeaderInfo
        {...props}
        data={data}
        image={data.media[0]}
        titleLabel={"Product Name"}
        onDelete={deleteProduct}
        onSave={editProduct}
      />

      {/* Body */}
      <Row className="contact-body">
        {/*General info in left side */}
        <Col xs="12" lg="4" xl="3" className="general-info">
          <GeneralInfo
            {...props}
            info={generalInfoInput}
            noDateRagne={true}
            handleOnStatusChange={props.onChange}
            onChange={props.onChange}
          />
        </Col>

        {/* Details Info */}
        <Col xs="12" lg="8" xl="9" className="group-list">
          <div>
            {/* Static Group Attribute */}

            {_.map(staticGroups, (group) =>
              group.label === "Price" ? (
                <PriceComponent {...props} />
              ) : group.label === "Category" ? (
                <CategoryComponent {...props} />
              ) : group.label === "Inventory" ? (
                <Inventory {...props} />
              ) : group.label === "Variants" ? (
                <Variant {...props} />
              ) : (
                <CollapseList
                  {...props}
                  group={group}
                  collapseCase={group.collapseCase}
                  name="name"
                  updateState={props.onChange}
                />
              )
            )}

            {/* Dynamic Group Attribute */}
            {_.map(groupAttributes, (group) =>
              group.inner_name === "shipping" ? (
                <Shipping {...props} group={group} />
              ) : (
                <CollapseList
                  {...props}
                  group={group}
                  usingFormContollerList={
                    group.inner_name === "seo" ? true : false
                  }
                  target={
                    group.inner_name === "seo" ? data[group.inner_name] : data
                  }
                  collapseCase="ListAttribute"
                  name="inner_name"
                  usingEnName={true}
                  targetName={group.inner_name}
                  updateState={props.onChange}
                />
              )
            )}
          </div>
        </Col>
      </Row>
    </Card>
  );
};

View.propTypes = {};

export default View;
