import React from "react"

const Loading = () => {
  return (
    <div className="my-5 pt-5">
      <img
        src="/assets/img/loading.gif"
        alt="{'Loading...'}"
        className="d-block m-auto"
      />
      <h3 className="text-center mt-3 text-gray">{"Loading..."}</h3>
    </div>
  )
}

export default Loading
