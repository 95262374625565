import React, { PropTypes } from "react";
import Collapse from "components/general/Collapse";
import { map } from "lodash";
import catalog from "redux/reducers/Ecommerce/catalog";
import { calculatePriceWithTax } from "helpers/general-functions";
import Form from "components/general/FormControllerList";
import _ from "lodash";
const View = (props) => {
  const { products_catalogs, data, catalogs } = props;
  console.log("price", data.catalogs);

  const getProductCatalog = (catalog) => {
    let productCatalog = _.find(
      products_catalogs,
      (productCatalog) => productCatalog.catalog_id._id === catalog
    );
    let catalogObj = _.find(catalogs, (cat) => cat._id === catalog);

    if (productCatalog === undefined) {
      console.log("productCatalo undefinde", catalogObj);

      let productCatalogs = products_catalogs.slice();
      productCatalogs.push({
        catalog_id: catalogObj,
        categories: [],
        product_id: data._id,
        cost: 0,
        final_price: 0,
        price: 0,
        taxable: false,
      });
      props.onChange({ products_catalogs: productCatalogs });
      productCatalog = _.last(products_catalogs);
    }
    return productCatalog;
  };
  return (
    <Collapse group={{ label: "Price", id: 31, open: false }}>
      {data.catalogs.map((catalog, index) => {
        let productCatalog = getProductCatalog(catalog);
        console.log("inside For loop", productCatalog);

        return (
          productCatalog && (
            <div className="p-l-25 p-r-25">
              <Collapse
                group={{
                  label: productCatalog.catalog_id.name_en,
                  id: productCatalog._id,
                  open: false,
                }}
              >
                <div>
                  <Form
                    {...props}
                    type={"text"}
                    label={"Currency"}
                    name={"name_en"}
                    value={productCatalog.catalog_id.currencies[0].name_en}
                    default={productCatalog.catalog_id.currencies[0].name_en}
                    target={productCatalog.catalog_id.currencies[0]}
                    targetName="products_catalogs"
                    disabled={true}
                    updateState={props.onChange}
                  />
                  <Form
                    {...props}
                    type={"decimal"}
                    label={"Price"}
                    name={"price"}
                    value={productCatalog.price}
                    default={productCatalog.price}
                    target={productCatalog}
                    targetName="products_catalogs"
                    updateState={props.onChange}
                  />
                  <Form
                    {...props}
                    type={"decimal"}
                    label={"Final Price"}
                    name={"final_price"}
                    value={
                      productCatalog.taxable
                        ? calculatePriceWithTax(
                            productCatalog.price,
                            productCatalog.catalog_id.tax.percentage
                          )
                        : productCatalog.final_price
                    }
                    default={
                      productCatalog.taxable
                        ? calculatePriceWithTax(
                            productCatalog.price,
                            productCatalog.catalog_id.tax.percentage
                          )
                        : productCatalog.final_price
                    }
                    target={productCatalog}
                    targetName="products_catalogs"
                    updateState={props.onChange}
                  />
                  <Form
                    {...props}
                    type={"decimal"}
                    label={"Cost"}
                    name={"cost"}
                    value={productCatalog.cost}
                    default={productCatalog.cost}
                    target={productCatalog}
                    targetName="products_catalogs"
                    updateState={props.onChange}
                  />
                  <Form
                    {...props}
                    type={"text"}
                    label={"Revenue"}
                    name={"revenue"}
                    value={productCatalog.price - productCatalog.cost}
                    default={productCatalog.cost}
                    target={productCatalog}
                    targetName="products_catalogs"
                    disabled={true}
                    updateState={props.onChange}
                  />

                  <Form
                    {...props}
                    type={"switch"}
                    label={"Taxable"}
                    name={"taxable"}
                    value={productCatalog.taxable}
                    default={productCatalog.taxable}
                    target={productCatalog}
                    targetName="products_catalogs"
                    id={`taxable${index}`}
                    updateState={props.onChange}
                  />
                </div>
              </Collapse>
            </div>
          )
        );
      })}
    </Collapse>
  );
};

View.propTypes = {};

export default View;
