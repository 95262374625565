import React, { useState } from "react";
import Card from "components/general/Card";
import Search from "components/general/Search";

import AddGroup from "./AddGroup";

import AddAttribute from "./AddAttribute";
import Group from "./Group";

const View = (props) => {
  const headerList = [
    {
      type: "id",
      isKey: true,
      hidden: true,
      title: "ID",
    },
    {
      sortable: true,
      type: "name_en",
      align: "center",
      title: "ATTRIBUTE LABEL",
    },
    {
      type: "inner_name",
      sortable: true,
      title: "INTERNAL NAME",
    },
    {
      type: "type",
      title: "Type",
      align: "center",
    },
    {
      type: "description_en",
      title: "ATTRIBUTE DESCRIPTION",
      align: "center",
    },
    {
      type: "updated_by",
      align: "center",
      title: "UPDATE BY",
    },
    {
      type: "LAST UPDATE",
      align: "center",
      title: "last_update",
    },
    {
      type: "action",
      sortable: true,
      align: "center",
      export: false,
      title: "Action",
    },
  ];

  return (
    <Card name="Ecommerce Attribute">
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <AddAttribute />
          <AddGroup />
        </div>
        <Search />
      </div>
      {props.groups.map((group) => (
        <Group {...props} group={group} headerList={headerList} />
      ))}
    </Card>
  );
};

export default View;
