import React, { useEffect } from "react";
import View from "./View";
const Index = (props) => {
  const { selectedAttributes } = props;
  useEffect(() => {
    // Get Config list
  }, [props.configAttributes]);

  const toggleOption = (attributeIndex, optionIndex) => {
    // Clone the options array
    const Attributeoptions = selectedAttributes.slice();
    let option = Attributeoptions[attributeIndex].options[optionIndex];

    // Toggle the option checked property
    if (Attributeoptions[attributeIndex]) {
      option.checked = option.checked !== undefined ? !option.checked : true;
    }

    // Update the component state
    props.updateSelectedAttribute(Attributeoptions);
    props.updateState({ isUpdate: true });
  };

  return (
    <div>
      <View {...props} toggleOption={toggleOption} />
    </div>
  );
};

Index.propTypes = {};

export default Index;
