import { update } from "lodash";
import React, { PropTypes, useState, useEffect } from "react";
import { Button } from "reactstrap";
import { getProducts } from "redux/actions/Ecommerce/ProductAction";
import View from "./View";
const Index = (props) => {
  const { selectedAttributes, variants } = props;

  const [uniqueState, setUniqueState] = useState({
    price: 0,
    quantity: 0,
  });

  useEffect(() => {
    if (props.isUpdate) {
      getVariants();
    }
  }, []);

  const getSelectedOptions = async () => {
    let selectedAttributesOpts = [];
    await selectedAttributes.map(({ ...attr }, index) => {
      attr.options = attr.options.filter((option) => option.checked);
      selectedAttributesOpts.push(attr);
    });
    props.updateSelectedAttributesOption(selectedAttributesOpts);
    return selectedAttributesOpts;
  };

  const modifierToArrayOfArray = (data) => {
    return data.map((item) => item.options.map((opt) => opt.value));
  };

  let generateVariants = (arrays) => {
    if (arrays.length === 0) {
      return [[]];
    }
    let results = [];

    generateVariants(arrays.slice(1)).forEach((product) => {
      arrays[0].forEach((value) => {
        results.push([value].concat(product));
      });
    });

    return results;
  };

  let getAllCombinations = (products, selectedAttributesOpts) => {
    return products.map((product, index) => {
      let obj = {
        sku: `${props.sku}-${index}`,
        media: [],
        price: 1,
        quantity: 1,
        status: "Active",
        type: "simple",
      };
      let productName = props.name_en;
      selectedAttributesOpts.forEach((attr, i) => {
        obj[attr.inner_name] = product[i];
        productName += `-${product[i]}`;
      });
      obj["name_en"] = productName;
      obj["name_ar"] = productName;

      return obj;
    });
  };

  const getVariants = async () => {
    let selectedAttributesOpts = await getSelectedOptions();
    let generatedVariants = generateVariants(
      modifierToArrayOfArray(selectedAttributesOpts)
    );
    let varaints = getAllCombinations(
      generatedVariants,
      selectedAttributesOpts
    );

    props.updateVariants(varaints);
    //FIXME:Logic will be difficult to understand need to change
    props.updateState({ isUpdate: false });
  };

  const onChangeState = (input) => {
    setUniqueState({ ...uniqueState, ...input });
  };

  const handleApply = (name) => {
    let products = variants.slice();
    products = products.map((e) => ({ ...e, [name]: uniqueState[name] }));
    props.updateVariants(products);
  };

  return (
    <View
      {...props}
      uniqueState={uniqueState}
      updateState={onChangeState}
      handleApply={handleApply}
    />
  );
};

Index.propTypes = {};

export default Index;
