import React, { Component } from "react";
import View from "./View";
import { connect } from "react-redux";
import slugify from "react-slugify";
import { addGroup } from "../../../../redux/actions/Ecommerce/GroupAction";

const initialState = {
  business_id: "5cc2f00577ebc157b4f1c64c",
  inner_name: "",
  label: "",
  arabic_label: "",
  is_default: false,
  modal: false,
  errors: null,
};
class index extends Component {
  state = initialState;

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeLabel = async (e) => {
    console.log("inside on Change label");
    await this.setState({ [e.target.name]: e.target.value });
    let inner_name_slug = slugify(this.state.label, { delimiter: "_" });
    this.setState({ inner_name: inner_name_slug });
  };

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  reset() {
    this.setState(initialState);
  }

  onValidate = (e) => {
    e.preventDefault();
    let errors = {};
    let inputs = this.state;
    if (
      !inputs.label ||
      inputs.inner_name === "" ||
      inputs.arabic_label === ""
    ) {
      errors.required = "Pleae fill required inputs";
      this.setState({ errors: errors });
    } else {
      this.addGroup(e);
    }
  };

  addGroup = (e) => {
    e.preventDefault();
    console.log("inside Add group");
    //business_id: "${this.state.business_id}",
    let group = {
    label: this.state.label,
    arabic_label: this.state.arabic_label,
    inner_name: this.state.inner_name,
    is_default: this.state.is_default,
    indexing_for: "crm",
    };
    console.log(group);

    try {
      if (!this.props.isEcomGroup) {
        let response = this.props.addGroup(JSON.stringify(group));
        console.log(response);
      } else
        this.props.addEcomGroup({
          label: this.state.label,
          arabic_label: this.state.arabic_label,
          inner_name: this.state.inner_name,
          is_default: this.state.is_default,
        });
    } catch (err) {
      console.log(err);
    }
    this.reset();
    this.toggle();
  };

  render() {
    return (
      <div>
        <View
          data={this.state}
          onChange={this.onChange}
          onChangeLabel={this.onChangeLabel}
          onSumbit={this.addGroup}
          modal={this.state.modal}
          toggle={this.toggle}
          errors={this.state.errors}
          onValidate={this.onValidate}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  group: state.group.item,
});

export default connect(mapStateToProps, { addGroup })(index);
