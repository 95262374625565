import React, { useState } from "react";
import { Table, Button } from "reactstrap";
import moment from "moment";
import DeleteAttribute from "../DeleteAttribute";
import ErrorAlert from "../../../../../components/general/ErrorAlert";
import _ from "lodash";

export default function View(props) {
  const { group } = props;

  const [willDeleteAttr, setWillDeleteAttr] = useState();

  const [deleteModal, setDeleteModal] = useState(false);

  const goToEditPage = (attr, group_inner_name) =>
    props.history.push({
      pathname: "/crm/edit_attribute",
      state: {
        attribute: attr,
        bussines_id: props.business_id,
        group_inner_name: group_inner_name,
      },
    });

  const toggleDeleteAttribute = (index) => {
    setDeleteModal(!deleteModal);

    //to find the attribute wanted to be delete
    let attr = _.find(group.attributes, (item, key) => key === index);

    if (attr) {
      setWillDeleteAttr(attr);
    }
  };

  const alertError = () => {
    ErrorAlert(
      "error",
      "Can not delete attribute , it's created by the system!"
    );
  };

  return (
    <div>
      <Table
        className="tabel table-striped 
          table-bordered 
          no-more-tables 
          table-hover 
          table-condensed 
          dataTable 
          tblDetails"
        id="tblDetails"
      >
        <thead>
          <tr role="row">
            <th style={{ display: "none" }} />
            <th
              style={{ width: "15%" }}
              className="sorting text-center"
              role="columnheader"
              aria-controls="example"
              rowSpan={1}
              colSpan={1}
              aria-sort="descending"
            >
              {"ATTRIBUTE LABEL"}
            </th>
            <th
              style={{ width: "15%" }}
              data-hide="phone,tablet"
              className="sorting text-center"
              role="columnheader"
              aria-controls="example"
              rowSpan={1}
              colSpan={1}
            >
              {"INTERNAL NAME"}
            </th>
            <th
              style={{ width: "10%" }}
              className="sorting text-center"
              role="columnheader"
              aria-controls="example"
              rowSpan={1}
              colSpan={1}
            >
              {"ATTRIBUTE TPYE"}
            </th>
            <th
              style={{ width: "15%" }}
              data-hide="phone,tablet"
              className="sorting text-center"
              role="columnheader"
              aria-controls="example"
              rowSpan={1}
              colSpan={1}
            >
              {"ATTRIBUTE DESCRIPTION"}
            </th>
            <th
              style={{ width: "10%" }}
              data-hide="phone,tablet"
              className="sorting text-center"
              role="columnheader"
              aria-controls="example"
              rowSpan={1}
              colSpan={1}
            >
              {"UPDATE BY"}
            </th>
            <th
              style={{ width: "10%" }}
              data-hide="phone,tablet"
              className="sorting text-center"
              role="columnheader"
              aria-controls="example"
              rowSpan={1}
              colSpan={1}
            >
              {"LAST UPDATE"}
            </th>
            <th
              style={{
                width: "10%",
                textAlign: "center",
              }}
              data-hide="phone,tablet"
              className="sorting text-center"
              role="columnheader"
              aria-controls="example"
              rowSpan={1}
              colSpan={1}
            >
              {"ACTION"}
            </th>
          </tr>
        </thead>

        <tbody role="alert" aria-live="polite" aria-relevant="all">
          {group.attributes.map((attr, index) => {
            return (
              <tr key={attr.inner_name} className="cursor-pointer">
                <td style={{ display: "none" }} />
                <td className="sorting_1" style={{ textAlign: "center" }}>
                  {attr.label}
                </td>

                <td className="v-align-middle" style={{ textAlign: "center" }}>
                  {attr.inner_name}
                </td>

                <td style={{ textAlign: "center" }}>{attr.type}</td>

                <td>{attr.desc}</td>

                <td style={{ textAlign: "center" }}>{attr.updated_by}</td>

                <td style={{ textAlign: "center" }}>
                  {attr.last_update
                    ? moment(attr.last_update).format("MMM d, YYYY")
                    : "NAN"}
                </td>

                <td style={{ textAlign: "center" }}>
                  {!attr.class ? (
                    <ul className="d-flex  justify-content-between action-groups">
                      <li>
                        <a>
                          <i
                            className="fa fa-pencil-square-o m-r-5"
                            onClick={() => goToEditPage(attr, group.inner_name)}
                          />
                        </a>
                      </li>
                      <li
                        data-toggle="tooltip"
                        title="Can't delete this attribute, It's created by  the system!"
                      >
                        <a className="disabeled">
                          {" "}
                          <i className="fa fa-trash" data-toggle="modal" />
                        </a>
                      </li>
                    </ul>
                  ) : (
                    <ul className="d-flex  justify-content-between action-groups">
                      <li>
                        <a>
                          <i
                            className="fa fa-pencil-square-o m-r-5"
                            onClick={() => goToEditPage(attr, group.inner_name)}
                          />
                        </a>
                      </li>
                      <li>
                        <a className="pointer" href="#">
                          <i
                            className="fa fa-trash"
                            color="blue"
                            onClick={() => toggleDeleteAttribute(index)}
                          />
                        </a>
                      </li>
                      <DeleteAttribute
                        attr={willDeleteAttr ? willDeleteAttr : attr}
                        modal={deleteModal}
                        toggle={toggleDeleteAttribute}
                      />
                    </ul>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}
