import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import View from "./View";
import _ from "lodash";

import { deleteBrand, editBrand } from "redux/actions/Ecommerce/BrandAction";
import { getCatalogs } from "redux/actions/Ecommerce/CatalogAction";
import SuccessAlert from "components/general/SuccessAlert";
import ErrorAlert from "components/general/SuccessAlert";

/** * FIXME: 
1- Check status if active or not 
3- Solve the problem of refresh the page 
  >> should show the updated recently data
  >> currently, it shows the old passed data version from location
*/

const Index = (props) => {
  let brand = props.location.state.item;
  brand.status = _.toLower(brand.status) === "active" ? true : false;
  brand.logos = _.isEmpty(brand.logos)
    ? [
        { size: "1:1 Size", source: null },
        { size: "16:9 Size", source: null },
      ]
    : brand.logos;

  let [state, setState] = useState(brand);

  useEffect(() => {}, [props.brand]);
  useEffect(() => {
    props.getCatalogs();
  }, []);

  const deleteBrand = (brandID) => {
    try {
      props.deleteBrand(brandID);
      SuccessAlert("success", "Sucessfully deleted");
      props.history.push("brand");
    } catch (err) {
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  const updateState = (input) => {
    console.log("Input", input);
    setState({ ...state, ...input });
  };

  const editBrand = async () => {
    try {
      state.status = state.status === true ? "active" : "inactive";

      let response = props.editBrand(JSON.stringify(state), state._id);
      SuccessAlert("success", "Sucessfully Edit");
    } catch (err) {
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  console.log("State", state);

  return props.catalogs ? (
    <div>
      {" "}
      <View
        {...state}
        data={state}
        {...props}
        deleteBrand={deleteBrand}
        editBrand={editBrand}
        onChange={updateState}
      />{" "}
    </div>
  ) : (
    <div></div>
  );
};
const mapStatToProps = (state) => ({
  catalogs: state.catalogs.items,
});
export default connect(mapStatToProps, {
  deleteBrand,
  editBrand,
  getCatalogs,
})(Index);
