export default async (url, method, data = null) => {


    console.log('NEW REQUEST! ', url);

    let postOptions = {
        method: method,
        headers: {
            "Content-Type": "application/json"
        },
        body: data
    }

    let getOptions = {
        method: method,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    }

    let options = data ? postOptions : getOptions

   
      return fetch(`${url}`, options)
       

}