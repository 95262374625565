import _ from "lodash"
/**
 *
 * @param {array} categories
 * @returns {array} categories levels
 */
export function categoryHandler(categories) {
  let handledCategories = []

  let topLevelCategories = _.filter(
    categories,
    (category) => _.isEmpty(category.parent)
  )
  console.log(topLevelCategories)

  _.forEach(categories, (cat) => {
    console.log(cat)

    if (cat.parent) {

      _.forEach(categories, (par) => {

        if (par._id === cat.parent._id) {
          cat.active = false
          cat.toggled = true
          cat.name = cat.name_en
          if (!_.isEmpty(par.children) && _.isEmpty(_.find(par.children, child => child._id === cat._id)))
            par.children = [...par.children, cat]

          if (_.isEmpty(par.children))
            par.children = [cat]
        }
      })
  
    } else {

      cat.children = []
      cat.active = true
      cat.toggled = false
      cat.name = cat.name_en
      console.log(cat)

      handledCategories.push(cat)
    }
  })

  return topLevelCategories

}