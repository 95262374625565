import React from "react";
import Card from "../../../../components/general/Card";
import Table from "../../../../components/general/Table";
import AddContact from "../AddContact";
const View = (props) => {
  // let options = {
  //     paginationShowsTotal: this.renderShowsTotal
  // }
  const { data, deleteCustomer } = props;

  let handleImport = (e) => {
    e.preventDefault();
    props.history.push("/import_export");
  };

  const headerList = [
    {
      type: "_id",
      isKey: true,
      hidden: true,
      title: "ID",
    },
    {
      sortable: true,
      type: "name",
      align: "center",
      title: "Name",
    },
    {
      type: "email",
      sortable: true,
      formatExtraData: {
        className: "fa-envelope-o",
        verified: "email_verified",
      },
      title: "Email",
    },
    {
      type: "phone",
      title: "Phone",
      formatExtraData: {
        className: "fa-phone",
        verified: "phone_verified",
      },
    },
    {
      type: "last_login",
      sortable: true,
      title: "Last Login",
      align: "center",
    },
    {
      type: "country",
      title: "Country",
      formatExtraData: "country",
      align: "center",
    },
    {
      type: "city",
      title: "City",
      align: "center",
    },
    {
      type: "status",
      align: "center",
      title: "Status",
    },
    {
      type: "action",
      sortable: true,
      align: "center",
      export: false,
      title: "Action",
    },
  ];

  return (
    <Card name={"Contact List"}>
      <div className="header-actions d-flex">
        <AddContact />
        <button
          type="button"
          className="btn btn-white btn-cons"
          onClick={handleImport}
        >
          {"Import"}
        </button>
        {/* Action Btn */}
        <div className="btn-group">
          <span
            className="btn btn-white dropdown-toggle btn-cons"
            data-toggle="dropdown"
          >
            {"Action"}
            <span className="caret" />
          </span>
          <ul className="dropdown-menu">
            <li>Edit Columns</li>
            <li>Edit Attribute</li>
            <li>Restore Contact</li>
            <li>Delete Contact</li>
          </ul>
        </div>
      </div>
      <div className="contact-table">
        {data && (
          <Table
            data={data}
            isSelectRow={false}
            EDIT_URL={"crm/contact_details"}
            deleteAction={deleteCustomer}
            headers={headerList}
            {...props}
          />
        )}
      </div>
      <div className="verified-container m-t-10">
        <ul className="list-unstyled">
          <li>
            <i className="fa fa-circle m-r-5"></i>
            {"Unverified information"}
          </li>
          <li>
            <i className="fa fa-circle verified m-r-5"></i>
            {"Verified information"}
          </li>
        </ul>
      </div>
    </Card>
  );
};

export default View;
