import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import View from "./View";
import { getGroups } from "redux/actions/Ecommerce/GroupAction";
import {
  editAttribute,
  deleteAttribute,
} from "redux/actions/Ecommerce/AttributeAction";
import { attributeEcomTypes } from "seeder/attributeTypes";
import { getTypeCase } from "helpers/general-functions";
import SuccessAlert from "components/general/SuccessAlert";
import ErrorAlert from "components/general/ErrorAlert";

class index extends Component {
  attribute = this.props.item
    ? this.props.item
    : this.props.location.state.item;
  id = this.props.id ? this.props.id : this.props.location.state.id;
  state = {
    //business_id: "5cc2f00577ebc157b4f1c64c",
    name_en: this.attribute.name_en,
    name_ar: this.attribute.name_ar,
    description_ar: this.attribute.description_ar,
    description_en: this.attribute.description_en,
    type: this.attribute.type,
    typeCase: getTypeCase(this.attribute.type),
    group_id: this.attribute.group_id,
    inner_name: this.attribute.inner_name,
    system_name: this.attribute.system_name,
    option: this.attribute.option,
    is_required: this.attribute.is_required,

    to_search: this.attribute.to_search,
    is_filtered: this.attribute.is_filtered,
    is_default: this.attribute.is_default,
    is_configurable: this.attribute.is_configurable,
    path: this.attribute.path,
    class: this.attribute.class,
    errors: null,
  };

  componentWillMount() {
    this.props.getGroups("ecommerce");
  }
  onChange = (input) => {
    this.setState(input);
  };

  deleteAttribute = (attributeID) => {
    console.log("delete Attribute", attributeID);
    try {
      let response = this.props.deleteAttribute(attributeID);
      SuccessAlert("success", "Sucessfully deleted");
      if (this.props.history) this.props.history.push("attribute");
      console.log(response);
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  editAttribute = () => {
    try {
      this.state = _.omit(this.state, "errors");
      this.props.editAttribute(JSON.stringify(this.state), this.attribute._id);

      SuccessAlert("success", "Sucessfully Add");
    } catch (error) {
      ErrorAlert("error", `Something went wrong! ${error}`);
    }
  };

  render() {
    console.log("EDit State", this.state);
    return (
      <div>
        <View
          {...this.props}
          onChange={this.onChange}
          data={this.state}
          deleteAction={this.deleteAttribute}
          groupsOfAttributes={this.props.groups}
          attributeTypes={attributeEcomTypes}
          id={this.id}
          onSubmit={this.editAttribute}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  groups: state.ecommerceGroups.items,
});

export default connect(mapStateToProps, {
  getGroups,
  editAttribute,
  deleteAttribute,
})(index);
