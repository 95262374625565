import React, { Component } from "react";
//import TableData from "../TableData"
import Table from "../../../../components/general/Table";
import Card from "../../../../components/general/Card";
import HeaderActions from "../../../../components/general/HeaderBtns";

//class List extends Component {
const View = (props) => {
  let { tags, deleteTag, handleCreate } = props;

  const headerList = [
    {
      type: "_id",
      isKey: true,
      hidden: true,
      title: "ID",
    },
    {
      sortable: true,
      type: "name",
      align: "center",
      title: "Name",
    },
    {
      sortable: true,
      type: "name",
      align: "center",
      title: "Segment",
    },
    {
      sortable: true,
      type: "name",
      align: "center",
      title: "Users",
    },
    {
      sortable: true,
      type: "name",
      align: "center",
      title: "Campaign",
    },
    {
      sortable: true,
      type: "name",
      align: "center",
      title: "Journey",
    },
    {
      sortable: true,
      type: "created_date",
      align: "center",
      title: "Creation Date",
    },
    {
      type: "status",
      align: "center",
      title: "Status",
    },
    {
      type: "action",
      sortable: true,
      align: "center",
      export: false,
      title: "Action",
    },
  ];
  return (
    <Card name="Tags">
      <HeaderActions
        createAction={{ title: "Create", onClick: handleCreate }}
        importAction={{ title: "Import", onClick: () => {} }}
        ActionList={{ title: "Action", list: "Delete Tag" }}
      />
      <div className="contact-table">
        <Table
          data={tags}
          isSelectRow={true}
          EDIT_URL={"crm/edit_tag"}
          deleteAction={deleteTag}
          headers={headerList}
          {...props}
        />
      </div>
      <div className="verified-container m-t-10">
        <ul className="list-unstyled">
          <li>
            <i className="fa fa-circle m-r-5"></i>
            {"Draft Tag: The Tag is not saved and can't use with any campaign."}
          </li>
          <li>
            <i className="fa fa-circle m-r-5 active"></i>
            {
              "Active Tag: The tag is active and currently used with active campaign."
            }
          </li>
          <li>
            <i className="fa fa-circle m-r-5 inactive"></i>
            {
              "Inactive Tag: The tag is inactive and currently not used with active campaign."
            }
          </li>
        </ul>
      </div>
    </Card>
  );
};

export default View;
