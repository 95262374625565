import React from "react";
import { CustomInput, FormGroup, Label } from "reactstrap";
import FormComponent from "../../../../../components/general/FormController";
import DatePicker from "react-datepicker";
import _ from "lodash"

const View = (props) => {
  const { onChange, data, customerTypes } = props;
  return (
    <div class="general-info">
      {/* <FormGroup className="d-flex">
        <Label className="mr-3">Status: </Label>
        <FormComponent
          type={"switch"}
          name={"contact_status"}
          label={"Contact Status"}
          value={data["contact_status"]}
          updateState={onChange}
      />
      <CustomInput type="switch" id="status" name="status" />
      </FormGroup> */}
      <FormComponent
        type={"switch"}
        name={"contact_status"}
        label={"Contact Status"}
        id={"contact_status"}
        classFormGroup="d-flex"
        classLabel="mr-3"
        value={data.contact_status}
        updateState={onChange}
      />
      <FormComponent
        type={"text"}
        name={"contact_id"}
        label={"Contact ID"}
        value={data["contact_id"]}
        default={data["contact_id"]}
        disabled={true}
      />
      <FormComponent
        type={"select"}
        options={_.map(customerTypes, type => ({ label: _.get(type, 'name_en'), value: _.get(type, '_id') }))}
        name={"customer_type"}
        label={"Customer Type"}
        value={data["customer_type"]}
        selected={data["customer_type"]}
        default={data["customer_type"]}
        updateState={onChange}
        valueLabel={data["customer_type"]}
        valueKey="value"
        labelKey="label"
      />
      <div class="date-range">
        <Label>{"Date Range:"}</Label>
        <div class="from">
          <Label>From</Label>
          <FormComponent
            type={"date"}
            name={"date_from"}
            value={data["date_from"]}
            updateState={onChange}
            // classFormGroup="d-flex justify-content-between align-items-center" */
          />
        </div>
        <div class="to">
          <Label>To</Label>
          <FormComponent
            type={"date"}
            name={"date_to"}
            value={data["date_to"]}
            updateState={onChange}
          />
        </div>
      </div>
    </div>
  );
};

export default View;
