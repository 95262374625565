import React, { useEffect, useState, PropTypes } from "react";
import { connect } from "react-redux";
import {
  getProducts,
  deleteProduct,
  setProduct,
} from "../../../redux/actions/Ecommerce/ProductAction";
import SuccessAlert from "../../../components/general/SuccessAlert";
import ErrorAlert from "../../../components/general/SuccessAlert";
import LoadingComponent from "../../../components/general/Loading";
import PaginationComponent from "../../../components/general/Pagination";
import AddProduct from "./Add";
import View from "./View";

const Index = (props) => {
  useEffect(() => {
    props.getProducts(1000, 1);
  }, [props.products]);

  const deleteProduct = (productID) => {
    try {
      let response = props.deleteProduct(productID);
      SuccessAlert("success", "Sucessfully deleted");
      console.log(response);
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };

  // To be used later instead of importing <AddProduct /> to be consistant.
  const handleCreate = (e) => {
    e.preventDefault();
    // NOTE:
    // A workaround soltuion for this is,
    // change modal state to true, and send it to the <AddProduct /> component as prop
  };

  const handleEdit = (item, id) => {
    props.setProduct(item);
    props.history.push({
      pathname: `/edit_product`,
      state: {
        id: id,
        item: item,
      },
    });
  };

  return (
    <div>
      {props.products ? (
        <>
          <View
            {...props}
            products={props.products.products}
            deleteProduct={deleteProduct}
            handleCreate={handleCreate}
            handleEdit={handleEdit}
          />
        </>
      ) : (
        <LoadingComponent />
      )}
    </div>
  );
};

Index.propTypes = {};

const mapStateToProps = (state) => ({
  products: state.products.items,
});

export default connect(mapStateToProps, {
  getProducts,
  deleteProduct,
  setProduct,
})(Index);
