import React, { Component } from "react";
import View from "./View";

export default class index extends Component {
  // search = () => {
  //   console.log("Search");
  //   window.$(document).ready(function() {
  //     window.$("#search").keyup(function() {
  //       var txtVal = window.$(this).val();
  //       if (txtVal != "") {
  //         window.$(".tblDetails").show();
  //         window.$.each(window.$(".tblDetails"), function() {
  //           var match = window.$("td:contains('" + txtVal + "')", this);
  //           if (match.length > 0) {
  //             window
  //               .$(this)
  //               .find("tr")
  //               .hide();
  //             window
  //               .$(this)
  //               .find("thead > tr")
  //               .show();
  //             window
  //               .$(match)
  //               .parent("tr")
  //               .show();
  //           } else window.$(this).hide();
  //         });
  //       } else {
  //         // When there is no input or clean again, show everything back
  //         window.$(this).find("tr").show();
  //         window.$("tbody > tr", this).show();
  //       }
  //       if (window.$(".tblDetails:visible").length == 0) {
  //         // window.$('#search').after('<p class="message">Sorry No results found!!!</p>');
  //       }
  //     });
  //   });
  // }

  filterSearch=() => {
    var $rows = window.$('.tblDetails tbody tr');
    window.$('#search').keyup(function() {
    var val = '^(?=.*\\b' + window.$.trim(window.$(this).val()).split(/\s+/).join('\\b)(?=.*\\b') + ').*$',
        reg = RegExp(val, 'i'),
        text;
    
    $rows.show().filter(function() {
        text = window.$(this).text().replace(/\s+/g, ' ');
        return !reg.test(text);
    }).hide();
    });
     
  }

  componentDidMount = () => {
    this.filterSearch();
  }
  componentDidUpdate = () => {
    this.filterSearch();
  }
  render() {
    return (
      <div>
        <View />
      </div>
    )
  }
}
