export const graphqlUrl = process.env.UX_GRAPHQL_PROXY_URL + "/graphql";
export const restfulUrl = process.env.UX_GRAPHQL_PROXY_URL;
export const business_id = `business_id: "5cc2f00577ebc157b4f1c64c"`;
export const AddCustomerUrl = `http://localhost:5000/customer/add`;
export const API_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000"
    : "http://164.90.253.18/crm-ecommerce-services";
export const General_URL = "http://143.244.200.209";
export const Order_URL = process.env.NODE_ENV === "development"? "http://localhost:4222" : "http://143.244.200.209/order";
export const SHIPPING_URL = process.env.NODE_ENV === "development"? "http://localhost:4000/api/shipments" : "http://143.244.200.209/shipping/api/shipments";


// export const General_URL = "http://164.90.253.18";
export const AUTH_ORDER_TEMP_URL = "http://localhost:3005";
export const AUTH_URL = "http://164.90.253.18/auth-service/api";
// Temporary using
export const ORDER_SETTING_URL =
  "http://164.90.253.18/order-service/api/settings";
export const PAYMENT_SETTING_URL = process.env.NODE_ENV === "development"? "http://localhost:4002/api/settings" : 
  "http://143.244.200.209/payment/api/settings";
export const SHIPPING_SETTING_URL = process.env.NODE_ENV === "development"? "http://localhost:4000/api/settings" :
  "http://143.244.200.209/shipping/api/settings";
