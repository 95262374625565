import React, { Component } from "react";
import View from "./View";
import { connect } from "react-redux";

import { login, loginWithGoogle } from "redux/actions/Auth/user";
import { useGoogleLogin } from "react-google-login";
import SuccessAlert from "components/general/SuccessAlert";
import ErrorAlert from "components/general/ErrorAlert";
import _ from "lodash";

class Index extends Component {
  state = {
    email: "",
    password: "",
    errors: {
      nameError: "",
    },
  };

  onChange = (input) => {
    this.setState({ ...this.state, ...input });
  };

  clientID =
    "678234694031-2eoiq8htrsbv5ltggrssj7uqvdl9ebm2.apps.googleusercontent.com";
  clientSecret = "SU3xylSwd2O1mzC2temo9Tbw";

  responseGoogle = (response) => {
    console.log(response);
    this.props.loginWithGoogle({
      tokenId: response.tokenId,
      // stores: ["5efbacb731179ebef0823f86"],
    });
    // console.log(response);
    // console.log("Token ID", response.tokenId);
  };

  // onSuccess = (res) => {
  //   console.log(res);
  // };
  // onFailure = (res) => {
  //   console.log(res);
  // };

  componentDidMount() {
    let authElm = document.querySelector("#auth");
    if (authElm) authElm.offsetParent.classList.add("auth-container");
  }

  componentWillUnmount() {
    let authElm = document.querySelector("#auth");
    if (authElm) authElm.offsetParent.classList.remove("auth-container");
    window.location.reload();
  }

  onValidate = (e) => {
    e.preventDefault();
    let errors = {
      nameError: "",
    };

    let inputs = this.state;

    if (inputs.email === "" || inputs.password === "") {
      errors.nameError = "Sorry, This field is required!";
    }

    if (errors.nameError === "") {
      this.sumbit(e);
    } else {
      this.setState({ errors: errors });
    }
  };

  handleSwitchToLogin = (e) => {
    e.preventDefault();
    this.props.history.push("/singup");
  };

  sumbit = (e) => {
    // e.preventDefault();
    let user = `{
        "email": "${this.state.email}",
        "password": "${this.state.password}"
      }`;

    console.log(user);

    try {
      this.props.login(user);
    } catch (err) {
      console.log(err);
      ErrorAlert("error", `Something went wrong! ${err}`);
    }
  };
  render() {
    return (
      <View
        data={this.state}
        onChange={this.onChange}
        onSumbit={this.sumbit}
        onValidate={this.onValidate}
        responseGoogle={this.responseGoogle}
        handleSwitchToSignup={this.handleSwitchToLogin}
        errors={this.state.errors}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  auth: state.auth,
});

export default connect(mapStateToProps, { login, loginWithGoogle })(Index);
