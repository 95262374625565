import {
  ADD_CONFIG_ATTRIBUTE,
  EDIT_CONFIG_ATTRIBUTE,
  EDIT_CONFIG_ATTRIBUTES,
  GET_CONFIG_ATTRIBUTES,
  FIND_CONFIG_ATTRIBUTE,
  DELETE_CONFIG_ATTRIBUTE,
} from "../../actions/Ecommerce/types";

const initialState = {
  items: null,
  item: null,
  list:null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_CONFIG_ATTRIBUTE:
    case EDIT_CONFIG_ATTRIBUTE:
    case DELETE_CONFIG_ATTRIBUTE:
    case FIND_CONFIG_ATTRIBUTE:
      return {
        ...state,
        item: action.payload,
      };
    case GET_CONFIG_ATTRIBUTES:
      return {
        ...state,
        items: action.payload,
      };
      case EDIT_CONFIG_ATTRIBUTES:
        return {
          ...state,
          list: action.payload,
        };

    default:
      return state;
  }
}
