import React, { PropTypes, useState, useEffect } from "react";
import Card from "../../../../components/general/Card";
import HeaderInfo from "../../../../components/general/PageDetails/HeaderInfo";
import GeneralInfo from "../../../../components/general/PageDetails/Info";
import CollapseList from "../../../../components/general/PageDetails/CollapseList";
import _ from "lodash";
import { Row, Col, Button, Label } from "reactstrap";
import { Treebeard } from "react-treebeard";
import { categoryHandler } from "../handler";
import AddCategory from "../AddCategory";

const View = (props) => {
  const {
    data,
    catalogs,
    deleteCategory,
    editCategory,
    groupAttributes,
    groupAttribute,
    categories,
    //catList,
    //onToggle,
    updateSelectedCategory,
    headerList,
  } = props;
  console.log(props);

  const [catList, setCatList] = useState([]);
  const [cursor, setCursor] = useState(false);
  const [selectedCatalog, setSelectedCatalog] = useState("");
  const [openAddModal, setOpenAddModal] = useState(false);

  useEffect(() => {
    let { selectedCat } = props;

    setSelectedCatalog(selectedCat);
  }, [props, selectedCatalog]);

  useEffect(() => {
    // Get categories list
    console.log(catList);
    let cats = props.categories;
    if (!_.isEmpty(selectedCatalog)) {
      cats = _.filter(cats, (cat) => cat.catalogs.includes(selectedCatalog));
      console.log(cats);
    }

    //if (/*!_.isEmpty(cats) &&*/ _.isEmpty(catList)) {
    let handledCategory = categoryHandler(cats);
    console.log(handledCategory);
    setCatList(handledCategory);

    //}
  }, [props.categories, /*catList,*/ selectedCatalog]);
  console.log(catList);

  const onToggle = (node, toggled) => {
    console.log("inside onToggle");
    console.log({ node, toggled, cursor });
    if (cursor) {
      cursor.active = false;
    }
    node.active = true;
    if (node.children) {
      node.toggled = toggled;
    }
    node.seo = !_.isEmpty(node.seo) ? node.seo : {};
    updateSelectedCategory(node);
    setCursor(node);
    //setCatList(Object.assign({}, catList))
    setCatList(catList);
  };

  let openModal = () => {
    setOpenAddModal(!openAddModal);
  };

  //TODO: change catalogsList to be readed from catalogs reducer after merge updated commits
  //REMOVE ME
  /* let catalogsList = _.map(catalogs, (catalog) => ({
    label: _.get(catalog, "name_en"),
    value: _.get(catalog, "_id"),
  }));*/

  //TODO: REMOVE ME
  /*let seoAttribute = _.filter(
    groupAttributes,
    (group) => group.inner_name === "seo"
  );*/

  let generalInfoInput = [
    /*{
      type: "switch",
      name: "status",
      id: "status",
      label: "Category Status",
      classFormGroup: "d-flex",
      classLabel: "mr-3",
      value: _.toLower(data.status) === "active" ? true : false,
      updateState: true,
    },
    {
      type: "text",
      name: "_id",
      label: "category ID",
      value: data["_id"],
      default: data["_id"],
      disabled: true,
    },*/
    {
      type: "select",
      options: catalogs,
      name: "selectedCat",
      label: "Catalogs",
      value: data["selectedCat"],
      default: data["selectedCat"],
      valueKey: "_id",
      labelKey: "name_en",
    },
  ];

  const staticGroups = [
    {
      id: 0,
      label: "Product Information",
      collapseCase: "ListAttribute",
      open: true,
      attributes: [
        {
          type: "switch",
          name: "status",
          id: "status",
          label: "Category Status",
          classFormGroup: "d-flex",
          classLabel: "mr-3",
          value: _.toLower(data.status) === "active" ? true : false,
          updateState: true,
        },
        {
          type: "switch",
          name: "isShownInMenu",
          id: "isShownInMenu",
          label: "Include in Menu",
          classFormGroup: "d-flex",
          classLabel: "mr-3",
          value: _.toLower(data.isShownInMenu) === true ? true : false,
          updateState: true,
        },
        {
          type: "text",
          name: "_id",
          label: "category ID",
          value: data["_id"],
          default: data["_id"],
          disabled: true,
        },
        {
          label: "English Name",
          name: "name_en",
          type: "text",
        },
        {
          label: "Arabic Name",
          name: "name_ar",
          system_name: "name_ar",
          type: "text",
        },
        {
          label: "English Description",
          name: "description_en",
          type: "textarea",
        },
        {
          label: "Arabic Description",
          name: "description_ar",
          type: "textarea",
        },
      ],
    },
    {
      id: 1,
      label: "Catalog",
      collapseCase: "ListAttribute",
      open: false,
      attributes: [
        {
          type: "groupCheckbox",
          option: catalogs,
          name: "catalogs",
          label: "Catalogs",
          value: data["catalogs"],
          default: data["catalogs"],
          valueKey: "_id",
          labelKey: "name_en",
        },
      ],
    },
    {
      id: 2,
      label: "Content",
      collapseCase: "content",
      open: false,
    },
    {
      id: 3,
      label: "Display Setting",
      collapseCase: "dislpaySetting",
      open: false,
    },
    {
      id: 4,
      label: "Product List",
      collapseCase: "table",
      open: false,
      items: data["products"],
    },
    {
      id: 5,
      label: "Search Engine Optimization",
      collapseCase: "ListAttribute",
      inner_name: "seo",
      open: false,
      attributes: [
        {
          type: "text",
          name: "url_key",
          label: "url key",
        },
        {
          type: "text",
          name: "meta_title_en",
          label: "Meta Title",
        },
        {
          label: "Meta Keywords",
          name: "meta_keyword_en",
          type: "text",
        },
        {
          label: "Meta Keywords Arabic",
          name: "meta_keyword_ar",
          system_name: "meta_keyword_ar",
          type: "text",
        },
        {
          label: "Meta Description",
          name: "meta_desc_en",
          type: "textarea",
        },
        {
          label: "Meta Description Arabic",
          name: "meta_desc_ar",
          type: "textarea",
        },
      ],
    },
  ];

  return (
    <Card name="Category Details">
      {/* Headr  */}
      <HeaderInfo
        {...props}
        data={data}
        titleLabel={"Category Name"}
        onDelete={deleteCategory}
        onSave={editCategory}
      />

      {/* Body */}
      <Row className="contact-body">
        {/*General info in left side */}
        <Col xs="12" lg="4" xl="3" className="general-info">
          <GeneralInfo
            {...props}
            info={generalInfoInput}
            noDateRagne={true}
            handleOnStatusChange={props.onChange}
            onChange={props.onChange}
          />
          <div>
            <Label>Category</Label>
            <Treebeard
              data={catList}
              onToggle={onToggle}
              /*decorators={decorators}*/
              style={{
                tree: {
                  base: {
                    listStyle: "none",
                    backgroundColor: "white",
                    margin: 0,
                    padding: 0,
                    color: "#9DA5AB",
                    fontFamily:
                      "lucida grande ,tahoma,verdana,arial,sans-serif",
                    fontSize: "14px",
                  },
                  node: {
                    activeLink: {
                      background: "white",
                    },
                  },
                },
              }}
            />
            <Row style={{ marginTop: "5%" }}>
              <Col className="text-right justify-content-end d-flex align-items-center">
                <Button
                  color="secondary"
                  onClick={openModal}
                  style={{ width: "100%", marginRight: "10px" }}
                >
                  {"Create new Category"}
                </Button>
              </Col>
            </Row>
            {openAddModal && <AddCategory {...props} isFromEdit={true} />}
          </div>
        </Col>

        {/* Details Info */}
        <Col xs="12" lg="8" xl="9" className="group-list">
          <div>
            {/* Static Group Attribute */}
            {_.map(staticGroups, (group) => (
              <CollapseList
                //{...props}
                headerList={headerList}
                catalogs={catalogs}
                data={data}
                group={group}
                usingFormContollerList={
                  group.inner_name === "seo" ? true : false
                }
                target={
                  group.inner_name === "seo" ? data[group.inner_name] : data
                }
                collapseCase={group.collapseCase}
                name="name"
                updateState={props.onChange}
              />
            ))}

            {/* Dynamic Group Attribute */}
            {/* {_.map(seoAttribute, (group) => (
              <CollapseList
                {...props}
                group={group}
                usingFormContollerList={
                  group.inner_name === "seo" ? true : false
                }
                target={
                  group.inner_name === "seo" ? data[group.inner_name] : data
                }
                collapseCase="ListAttribute"
                name="inner_name"
                usingEnName={true}
                targetName={group.inner_name}
                updateState={props.onChange}
              />
              ))}*/}
          </div>
        </Col>
      </Row>
    </Card>
  );
};

View.propTypes = {};

export default View;
