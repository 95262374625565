import React from "react";
import Table from "../../../components/general/Table";
import Card from "../../../components/general/Card";
import HeaderActions from "../../../components/general/HeaderBtns";
import AddCatalog from "./Add";

const View = (props) => {
  let { catalogs, deleteCatalog, handleCreate } = props;

  const headerList = [
    {
      type: "_id",
      isKey: true,
      hidden: true,
      title: "ID",
    },
    {
      sortable: true,
      type: "name_en",
      align: "center",
      title: "Name EN",
    },
    {
      sortable: true,
      type: "name_ar",
      align: "center",
      title: "Name",
    },
    {
      sortable: true,
      type: "description_ar",
      align: "center",
      title: "Description",
    },
    {
      sortable: true,
      type: "status",
      align: "center",
      title: "Show in Menu",
    },
    {
      type: "action",
      sortable: true,
      align: "center",
      export: false,
      title: "Action",
    },
  ];
  return (
    <>
      <Card>
        <HeaderActions
          createAction={false}
          importAction={{ title: "Import", onClick: () => {} }}
          ActionList={{ title: "Action", list: "Delete catalogation" }}
        >
        <AddCatalog {...props} />
        </HeaderActions>
        <div className="contact-table">
          <Table
            data={catalogs}
            isSelectRow={true}
            EDIT_URL={"ecommerce/edit_catalog"}
            deleteAction={deleteCatalog}
            headers={headerList}
            {...props}
          />
        </div>
        <div className="verified-container m-t-10">
          <ul className="list-unstyled">
            <li>
              <i className="fa fa-circle m-r-5"></i>
              {
                "Draft catalog: The catalog is not saved and can't use with any campaign."
              }
            </li>
            <li>
              <i className="fa fa-circle m-r-5 active"></i>
              {
                "Active catalog: The catalog is active and currently used with active campaign."
              }
            </li>
            <li>
              <i className="fa fa-circle m-r-5 inactive"></i>
              {
                "Inactive catalog: The catalog is inactive and currently not used with active campaign."
              }
            </li>
          </ul>
        </div>
      </Card>
    </>
  );
};

export default View;
