import React, { PropTypes } from "react";
import Card from "../../../../components/general/Card";
import HeaderInfo from "../../../../components/general/PageDetails/HeaderInfo";
import GeneralInfo from "../../../../components/general/PageDetails/Info";
import CollapseList from "../../../../components/general/PageDetails/CollapseList";
import _ from "lodash";
import { Row, Col, Button } from "reactstrap";

const View = (props) => {
  const { data, deleteBrand, editBrand, groupAttributes } = props;

  let generalInfoInput = [
    {
      type: "switch",
      name: "status",
      id: "status",
      label: "Brand Status",
      classFormGroup: "d-flex",
      classLabel: "mr-3",
      value: data.status,
      updateState: true,
    },
    {
      type: "text",
      name: "_id",
      label: "Brand ID",
      value: data["_id"],
      default: data["_id"],
      disabled: true,
    },
    {
      type: "groupCheckbox",
      options: props.catalogs,
      name: "catalogs",
      label: "Catalogs",
      value: data["catalogs"],
      default: data["catalogs"],
      valueKey: "_id",
      labelKey: "name_en",
    },
  ];

  const staticGroups = [
    {
      id: 0,
      label: "Brand Information",
      collapseCase: "ListAttribute",
      open: true,
      attributes: [
        {
          label: "Brand ID",
          name: "_id",
          type: "text",
          disabled: true,
        },
        {
          label: "English Name",
          name: "name_en",
          type: "text",
        },
        {
          label: "Arabic Name",
          name: "name_ar",
          type: "text",
        },
        {
          label: "Priority",
          name: "priority",
          type: "text",
        },
      ],
    },
    {
      id: 1,
      label: "Brand Logo",
      collapseCase: "Media",
      open: false,
      name: "logos",
      multiple: false,
      listOfObject: true,
      srcKey: "source",
      labelKey: "size",
    },
    {
      id: 3,
      label: "Brand Assets",
      collapseCase: "price",
      open: false,
    },
    {
      id: 4,
      label: "Product List",
      collapseCase: "table",
      items: data["products"],
      open: false,
    },
  ];

  const headerList = [
    {
      type: "id",
      isKey: true,
      hidden: true,
      title: "ID",
    },
    {
      sortable: true,
      type: "sku",
      align: "center",
      title: "sku",
    },
    {
      type: "name_en",
      sortable: true,
      title: "Name",
    },
    {
      type: "name_ar",
      title: "Name Ar",
    },
    {
      type: "type",
      title: "Type",
      align: "center",
    },
    {
      type: "status",
      align: "center",
      title: "Status",
    },
  ];

  return (
    <Card name="Product Details">
      {/* Headr  */}
      <HeaderInfo
        {...props}
        data={data}
        image={data.logos[0].source}
        titleLabel={"Brand Name"}
        onDelete={deleteBrand}
        onSave={editBrand}
      />

      {/* Body */}
      <Row className="contact-body">
        {/*General info in left side */}
        <Col xs="12" lg="4" xl="3" className="general-info">
          <GeneralInfo
            {...props}
            info={generalInfoInput}
            noDateRagne={true}
            handleOnStatusChange={props.onChange}
            onChange={props.onChange}
          />
        </Col>

        {/* Details Info */}
        <Col xs="12" lg="8" xl="9" className="group-list">
          <div>
            {/* Static Group Attribute */}
            {_.map(staticGroups, (group) => (
              <CollapseList
                {...props}
                group={group}
                collapseCase={group.collapseCase}
                name="name"
                updateState={props.onChange}
                headerList={headerList}
              />
            ))}
          </div>
        </Col>
      </Row>
    </Card>
  );
};

View.propTypes = {};

export default View;
