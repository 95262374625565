//ORDER
export const ADD_ORDER = "ADD_ORDER";
export const GET_ORDERS = "GET_ORDERS";
export const DELETE_ORDER = "DELETE_ORDER";
export const EDIT_ORDER = "EDIT_ORDER";
export const FIND_ORDER = "FIND_ORDER";

//Shipment
export const ADD_SHIPMENT = "ADD_SHIPMENT";
export const GET_SHIPMENTS = "GET_SHIPMENTS";
export const DELETE_SHIPMENT = "DELETE_SHIPMENT";
export const EDIT_SHIPMENT = "EDIT_SHIPMENT";
export const FIND_SHIPMENT = "FIND_SHIPMENT";
