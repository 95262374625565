import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import View from "./View";
import { getGroups } from "redux/actions/Ecommerce/GroupAction";
import { addAttribute } from "redux/actions/Ecommerce/AttributeAction";
import { attributeEcomTypes } from "seeder/attributeTypes";
import SuccessAlert from "components/general/SuccessAlert";
import ErrorAlert from "components/general/ErrorAlert";

class index extends Component {
  initialState = () => {
    return {
      //business_id: "5cc2f00577ebc157b4f1c64c",
      name_en: "",
      name_ar: "",
      description_ar: "",
      description_en: "",
      type: "",
      typeCase: "",
      group_id: "",
      inner_name: "",
      system_name: "",
      options: [
        {
          value: "",
          label: "",
          arabic_label: "",
          is_default: true,
        },
      ],
      is_required: true,
      in_form: false,
      to_search: false,
      is_filtered: false,
      is_default: false,
      is_configurable: this.props.configurable ? true : false,
      path: String,
      class: "custom",
      modal: false,
      errors: null,
    };
  };
  state = this.initialState();

  componentWillMount() {
    this.props.getGroups("ecommerce");
  }
  onChange = (input) => {
    this.setState(input);
  };

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  addAttribute = () => {
    let option = _.isEmpty(_.head(this.state.options).label)
      ? []
      : this.state.options;
    try {
      this.toggle();
      this.state = _.omit(this.state, "modal");
      this.state = _.omit(this.state, "errors");
      this.state = _.omit(this.state, "options");
      this.state.path = this.state.inner_name;
      this.state.label = this.state.name_en;
      this.state.option = option;
      this.state.inner_name = `attr_#${this.state.type}_#${this.state.inner_name}`;
      this.state.system_name = this.state.inner_name;
      let response = this.props.addAttribute(JSON.stringify(this.state));
      SuccessAlert("success", "Sucessfully Add");
    } catch (error) {
      ErrorAlert("error", `Something went wrong! ${error}`);
    }
  };

  render() {
    console.log("State", this.state);
    console.log("Groups", this.props.groups);

    return (
      <div>
        <View
          onChange={this.onChange}
          data={this.state}
          groupsOfAttributes={this.props.groups}
          attributeTypes={attributeEcomTypes}
          modal={this.state.modal}
          toggle={this.toggle}
          onSubmit={this.addAttribute}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  groups: state.ecommerceGroups.items,
});

export default connect(mapStateToProps, { getGroups, addAttribute })(index);
