import React from "react";
import { Route, Switch } from "react-router-dom";

import Product from "../views/Ecommerce/Product";
import EditProduct from "../views/Ecommerce/Product/Edit";
import Category from "../views/Ecommerce/Category";
import EditCategory from "../views/Ecommerce/Category/EditCategory";
import Catalog from "../views/Ecommerce/Catalog";
import EditCatalog from "../views/Ecommerce/Catalog/Edit";
import Branch from "../views/Ecommerce/Branch";
import EditBranch from "../views/Ecommerce/Branch/Edit";
import Brand from "../views/Ecommerce/Brand";
import EditBrand from "../views/Ecommerce/Brand/Edit";
import AttributeEcommerce from "../views/Ecommerce/Attribute";
import EditAttribute from "../views/Ecommerce/Attribute/EditAttribute";
import MediaLibrary from "../components/general/MediaLibrary";
import Promotion from "../views/Ecommerce/Promotion";
import AddPromotion from "../views/Ecommerce/Promotion/Add";

import EditPromotion from "../views/Ecommerce/Promotion/Edit";
import Settings from "../views/Settings/index";
import CountrySetting from "../views/Settings/Ecommerce/Country";
import LanguageSetting from "../views/Settings/Ecommerce/Language";

import QuickNavigation from "../components/layouts/QuickNavigation";

const QuickNavList = [
  {
    title: "Products",
    src: "/assets/img/svg/contact-list.svg",
    path: "/ecommerce/product",
  },
  {
    title: "Category",
    src: "/assets/img/svg/segmentation.svg",
    path: "/ecommerce/category",
  },
  /*{
    title: "Catalog",
    src: "/assets/img/svg/segmentation.svg",
    path: "/ecommerce/catalog",
  },*/
  {
    title: "Branch",
    src: "/assets/img/svg/automated-rules.svg",
    path: "/ecommerce/branch",
  },
  {
    title: "Brand",
    src: "/assets/img/svg/automated-rules.svg",
    path: "/ecommerce/brand",
  },
  {
    title: "Promotion",
    src: "/assets/img/svg/automated-rules.svg",
    path: "/ecommerce/promotion",
  },
  {
    title: "Product Attributes",
    src: "/assets/img/svg/profile-attribute.svg",
    path: "/ecommerce/attribute",
  },
  {
    title: "Import / Export",
    src: "/assets/img/svg/import-export.svg",
    path: "/import_export",
  },
  // {
  //   title: "Country",
  //   src: "/assets/img/svg/import-export.svg",
  //   path: "/ecommerce/country",
  // },
];
const ecRoutes = () => {
  const currentPath = "/ecommerce";
  return (
    <div>
      <QuickNavigation quickNavs={QuickNavList} />
      <Switch>
        <Route exact path={`${currentPath}`} component={Product} />
        <Route exact path={`${currentPath}/product`} component={Product} />
        <Route path={`${currentPath}/edit_product`} component={EditProduct} />

        <Route path={`${currentPath}/category`} component={Category} />
        <Route path={`${currentPath}/edit_category`} component={EditCategory} />

        <Route path={`${currentPath}/catalog`} component={Catalog} />
        <Route path={`${currentPath}/edit_catalog`} component={EditCatalog} />

        <Route path={`${currentPath}/branch`} component={Branch} />
        <Route path={`${currentPath}/edit_branch`} component={EditBranch} />

        <Route path={`${currentPath}/brand`} component={Brand} />
        <Route path={`${currentPath}/edit_brand`} component={EditBrand} />

        <Route path={`${currentPath}/promotion`} component={Promotion} />
        <Route path={`${currentPath}/add-promotion`} component={AddPromotion} />

        <Route
          path={`${currentPath}/edit_promotion`}
          component={EditPromotion}
        />
        <Route path={`${currentPath}/media`} component={MediaLibrary} />

        <Route
          path={`${currentPath}/attribute`}
          component={AttributeEcommerce}
        />
        <Route
          path={`${currentPath}/edit_attribute`}
          component={EditAttribute}
        />

        {/*<Route path={`${currentPath}/setting`} component={Settings} />
        <Route path={`${currentPath}/country`} component={CountrySetting} />
        <Route path={`${currentPath}/language`} component={LanguageSetting} />
  */}
      </Switch>
    </div>
  );
};

export default ecRoutes;
