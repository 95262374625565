import React, { Component } from 'react'

class ImportExport extends Component {
	render() {
		return (
			<div className="import-export">
				<div className="clearfix"></div>
				<div className="row-fluid">
					<div className="span12">
						<div className="grid simple">
							<div className="grid-title">
								<h4>Import<span className="semi-bold m-r-5 m-l-5">&#47;</span>Export</h4>
								<div className="tools">
									<a href="javascript:;" className="collapse" />
								</div>
							</div>
                        </div>
					</div>
				</div>
			</div>
		);
	}
}

export default ImportExport
